import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from "@angular/material/dialog";
import { DataSharingComponent } from '../data-sharing/data-sharing.component';
import { Overlay} from '@angular/cdk/overlay';
import pdfMake from "pdfmake/build/pdfmake";  
import pdfFonts from "pdfmake/build/vfs_fonts";  

import {abcfactor} from '../services/abcfactor.service'
import {qvaluefactor} from '../services/qvalue-factor.service'
import {hrvalues} from '../services/hrvalues.service'
import {stabilityService} from '../services/stability.service'
import { ForAllService } from '../services/for-all.service';
import { hrtime } from 'process';
import * as Q from 'q';
import { isUndefined } from 'util';

pdfMake.vfs = pdfFonts.pdfMake.vfs;  
let externalDataRetrievedFromServer = [
  { name: 'Bartek', age: 34 ,int:1},
  { name: 'John', age: 27 ,int:2},
  { name: 'Elizabeth', age: 30 ,int:3},
];
let QModifiedRange=[
  {Modified_Q_range:"0.001 – 0.01"	,Category:"Exceptionally poor"},
  {Modified_Q_range:"0.01 – 0.1"	,Category:"Extremely poor"},
  {Modified_Q_range:"0.1 – 1.0"	,Category:"Very poor"},
  {Modified_Q_range:"1.0 – 4.0"	,Category:"poor"},
  {Modified_Q_range:"4.0 – 10"	,Category:"Fair"},
  {Modified_Q_range:"10 – 40"	,Category:"Good"},
  {Modified_Q_range:"40 – 100"	,Category:"Very good"},
  {Modified_Q_range:"100 – 1000"	,Category:"Exceptionally extremely good"},

]
let QModifiedRating=[

]
let RQDRange=[
{Stope_Range:"0 < RQD ≤ 25"	,Rating:"Very poor"}, 
{Stope_Range:"25 < RQD ≤ 50	"	,Rating:"Poor"}, 
{Stope_Range:"50 < RQD ≤ 75"	,Rating:"Fair"}, 
{Stope_Range:"75 < RQD ≤ 100"	,Rating:"Good"}, 


]

let RQDCheck=[
  {Stope_Range:"0 < RQD ≤ 25"	  ,RQD:2,Rating:"Very poor"}, 
  {Stope_Range:"25 < RQD ≤ 50	"	,RQD:2,Rating:"Poor"}, 
  {Stope_Range:"50 < RQD ≤ 75"	,RQD:2,Rating:"Fair"}, 
  {Stope_Range:"75 < RQD ≤ 100"	,RQD:2,Rating:"Good"}, 
  
  
  ]
let RQDRating=[

]

let ABCRating=[

]
let ABCRating2=[

]


let HrValues = [
  // {" ": "  ",Crown:""	,Hangingwall:""	,Footwall: ""	,Sidewall:""}

];
function getRating(data,data2){


  if(data2==1){
    if(data>0.001 && data<= 0.01){
      return "Exceptionally poor"
    }
    else if(data<= 0.1){
      return "Extremely poor"
    }
    else if(data<= 1.0){
      return "Very poor"
    }
    else if(data<= 4.0){
      return "poor"
    }
    else if(data<= 10){
      return "Fair"
    }
    else if(data<= 40){
      return "good"
    }
    else if( data<=100){
      return "Very good"
    }
    else if(data<= 1000){
      return "Exceptionally extremely good"
    }
    else{
      return "Error"
    }
  }
  else{
  if(  data>0 && data<=25){

  	return "Very poor"
  }
if(data<=50){

	return "Poor"
} 
if(data<=75){

	return "Fair"}

if(data<=100){

	return "good"
} 
  }
}
function buildTableBody(data, columns) {
  var body = [];

  body.push(columns);

  data.forEach(function(row) {
      var dataRow = [];

      columns.forEach(function(column) {
          dataRow.push(row[column].toString());
      })

      body.push(dataRow);
  });

  return body;
}
function table(data, columns) {
  return {
      table: {
          headerRows: 1,
          body: buildTableBody(data, columns)
      }
  };
}
async function gethrvalues(){
  
}
function pushQrating(data){
  QModifiedRating.push(data)
}
function setRow(data){
  HrValues.push(data)
}
@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CompleteComponent>, private dialog: MatDialog,private overlay: Overlay,private abcService :abcfactor,private hrService :hrvalues,private sService :stabilityService,private qService :qvaluefactor,private service : ForAllService) { }
  public crownN_mod: number;
  public side1N_mod: number;
  public side2N_mod: number;
  public hwN_mod: number;
  public fwN_mod: number;

  public timeFactor_crown: number;
  public timeFactor_hw: number;
  public timeFactor_fw: number;
  public timeFactor_sw1: number;
  public timeFactor_sw2: number;
  
  public faultFactor_crown: number;
  public faultFactor_hw: number;
  public faultFactor_fw: number;
  public faultFactor_sw1: number;
  public faultFactor_sw2: number;

  public crown_Afactor_modified: number;
  public crown_Bfactor_modified: number;
  public crown_Cfactor_modified: number;

  public hw_Afactor_modified: number;
  public hw_Bfactor_modified: number;
  public hw_Cfactor_modified: number;

  public fw_Afactor_modified: number;
  public fw_Bfactor_modified: number;
  public fw_Cfactor_modified: number;

  public side1_Afactor_modified: number;
  public side1_Bfactor_modified: number;
  public side1_Cfactor_modified: number;

  public side2_Afactor_modified: number;
  public side2_Bfactor_modified: number;
  public side2_Cfactor_modified: number;
  selectednew():void{
window.location.href="/";
  }
  selected() :void{
    console.log("CLicked")

    let dd0=[  {Type:'Shape Factor',Crown:0	,Hangingwall:0	,Footwall: 0	,Sidewall:0}
  ]
  let dd1=[  {Type:'Area',Crown:0	,Hangingwall:0	,Footwall: 0	,Sidewall:0}
  ]
  let dd2=[  {Type:'Perimeter',Crown:0	,Hangingwall:0	,Footwall: 0	,Sidewall:0}
  ]
  while(HrValues.length!=0){
    HrValues.pop()
  }
console.log("jjjjjj")
    this.service.currentHrCrown.subscribe(hrCrown => {dd0[0].Crown=hrCrown})
    this.service.currentHrFoot.subscribe(hrFoot => {dd0[0].Footwall=hrFoot})
    this.service.currentHrSide.subscribe(hrSide => {dd0[0].Sidewall=hrSide})
    this.service.currentHrHang.subscribe(hrHang =>{dd0[0].Hangingwall=hrHang})

    setRow(dd0[0])
    // dd0[0].Type="Area"
    this.service.currentCrownA.subscribe(crownA => dd1[0].Crown=crownA)
    this.service.currentFootA.subscribe(footA => dd1[0].Footwall=footA)
    this.service.currentHangA.subscribe(hangA => dd1[0].Hangingwall=hangA)
    this.service.currentSideA.subscribe(SideA => dd1[0].Sidewall=SideA)

    setRow(dd1[0])

    // dd0[0].Type="Perimeter"
    this.service.currentCrownP .subscribe(crownP => dd2[0].Crown=crownP)
    this.service.currentFootP .subscribe(footP => dd2[0].Footwall=footP)
    this.service.currentHangP .subscribe(hangP => dd2[0].Hangingwall=hangP)
    this.service.currentSideP.subscribe(SideP => dd2[0].Sidewall=SideP)
    setRow(dd2[0])
    this.service.currentQRes_crown.subscribe(xx=>console.log("Q VALUE    ",xx))
    //console.log("Hrvalues    -->  ",HrValues)
    
    //Table 1 done
//Table 2 start


while(QModifiedRating.length!=0){
  QModifiedRating.pop()
}

console.log("\n\n CLEARIGN RATING : ",QModifiedRating)
if(this.abcService.getAbcStopes().includes("Crown") ){
  let qData={
    Stope_Surface:"",
      QRes:	0,
      Rating :""
  }
  qData.Stope_Surface="Crown"

  this.service.currentQRes_crown.subscribe(xx=>qData.QRes=xx)
  this.service.currentQQuality_crown.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined crown")
      
      this.service.currentQRes_crown.subscribe(yy=>{
        console.log("YYY crown  ",yy)
        qData.Rating=getRating(yy,1)

      })
    }
    else
    qData.Rating=xx})
 
pushQrating(qData)
}
if(this.abcService.getAbcStopes().includes("HW") ){
  let qData={
    Stope_Surface:"",
      QRes:	0,
      Rating :""
  }
  qData.Stope_Surface="Hangingwall"
  this.service.currentQRes_hw.subscribe(xx=>qData.QRes=xx)
  this.service.currentQQuality_hw.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined hw")
      
      this.service.currentQRes_hw.subscribe(yy=>{
        console.log("YYY hw  ",yy)
        qData.Rating=getRating(yy,1)

      })
    }
    else
    qData.Rating=xx
  }
  )
pushQrating(qData)
}
if(this.abcService.getAbcStopes().includes("FW") ){
  let qData={
    Stope_Surface:"",
      QRes:	0,
      Rating :""
  }
  qData.Stope_Surface="Footingwall"
  this.service.currentQRes_fw.subscribe(xx=>qData.QRes=xx)
  this.service.currentQQuality_fw.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined fw")
      // qData.Rating=getRating(qData.QRes)
      this.service.currentQRes_fw.subscribe(yy=>{
        qData.Rating=getRating(yy,1)
      })
    }
    else
    qData.Rating=xx
  
  })
pushQrating(qData)
}
if(this.abcService.getAbcStopes().includes("SW1") ){
  let qData={
    Stope_Surface:"",
      QRes:	0,
      Rating :""
  }
  qData.Stope_Surface="Sidewall 1"
  this.service.currentQRes_sw1.subscribe(xx=>qData.QRes=xx)
  this.service.currentQQuality_sw1.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined sw1")
      // qData.Rating=getRating(qData.QRes)
      this.service.currentQQuality_sw1.subscribe(yy=>{
        qData.Rating=getRating(yy,1)
      })
    }
    else
    qData.Rating=xx
  })
pushQrating(qData)
}
if(this.abcService.getAbcStopes().includes("SW2") ){
  let qData={
    Stope_Surface:"",
      QRes:	0,
      Rating :""
  }
  qData.Stope_Surface="Sidewall 2"
  this.service.currentQRes_sw2.subscribe(xx=>qData.QRes=xx)
  this.service.currentQQuality_sw2.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined sw2")
      this.service.currentQQuality_fw.subscribe(yy=>{
        qData.Rating=getRating(yy,1)
      })

      
    }
    else
    qData.Rating=xx})
pushQrating(qData)
}




 console.log("RATIIING    : ",QModifiedRating)






 //////////////////////////////////////////////////////
//rqd calculation

while(RQDRating.length!=0){
  RQDRating.pop()
}

console.log("\n\n CLEARIGN RATING : ",RQDRating)
if(this.abcService.getAbcStopes().includes("Crown") ){
  let qData={
    Stope_Surface:"",
      RQD:	0,
      Rating :""
  }
  qData.Stope_Surface="Crown"
  // this.service.currentRqdQuality_crown.subscribe(yy=>{
  //   qData.Rating=getRating(yy)
  // })
  this.service.currentRqd_crown.subscribe(xx=>qData.RQD=xx)
  this.service.currentRqdQuality_crown.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined crown")
      
      this.service.currentRqd_crown.subscribe(yy=>{
        console.log("YYY crown  ",yy)
        qData.Rating=getRating(yy,0)//rqd rating checking
        // qData.Rating=getRating(qData.QRes)

      })
    }
    else
    qData.Rating=xx})
 
    RQDRating.push(qData)
}
if(this.abcService.getAbcStopes().includes("HW") ){
  let qData={
    Stope_Surface:"",
      RQD:	0,
      Rating :""
  }
  qData.Stope_Surface="Hangingwall"
  this.service.currentRqd_hw.subscribe(xx=>qData.RQD=xx)
  this.service.currentRqdQuality_hw.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined hw")
      
      this.service.currentRqd_hw.subscribe(yy=>{
        console.log("YYY hw  ",yy)
        qData.Rating=getRating(yy,0)
        // qData.Rating=getRating(qData.RQD)

      })
    }
    else
    qData.Rating=xx
  }
  )
  RQDRating.push(qData)
}
if(this.abcService.getAbcStopes().includes("FW") ){
  let qData={
    Stope_Surface:"",
      RQD:	0,
      Rating :""
  }
  qData.Stope_Surface="Footingwall"
  this.service.currentRqd_fw.subscribe(xx=>qData.RQD=xx)
  this.service.currentRqdQuality_fw.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined fw")
      // qData.Rating=getRating(qData.RQD)
      this.service.currentRqd_fw.subscribe(yy=>{
        qData.Rating=getRating(yy,0)
      })
    }
    else
    qData.Rating=xx
  
  })
  RQDRating.push(qData)
}
if(this.abcService.getAbcStopes().includes("SW1") ){
  let qData={
    Stope_Surface:"",
      RQD:	0,
      Rating :""
  }
  qData.Stope_Surface="Sidewall 1"
  this.service.currentRqd_sw1.subscribe(xx=>qData.RQD=xx)
  this.service.currentRqdQuality_sw1.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined sw1")
      // qData.Rating=getRating(qData.RQD)
      this.service.currentRqd_sw1.subscribe(yy=>{
        qData.Rating=getRating(yy,0)
      })
    }
    else
    qData.Rating=xx
  })
  RQDRating.push(qData)
}
if(this.abcService.getAbcStopes().includes("SW2") ){
  let qData={
    Stope_Surface:"",
      RQD:	0,
      Rating :""
  }
  qData.Stope_Surface="Sidewall 2"
  this.service.currentRqd_sw2.subscribe(xx=>qData.RQD=xx)
  this.service.currentRqdQuality_sw2.subscribe(xx=>{
    if(isUndefined(xx)){
      console.log("Undefined sw2")
      this.service.currentRqd_sw2.subscribe(yy=>{
        qData.Rating=getRating(yy,0)
      })

      
    }
    else
    qData.Rating=xx})
    RQDRating.push(qData)
}
 ////////////////////////////////////////////////////

console.log("RQD RATTIG CHRCKKK : ",RQDRating)
console.log("RQD RATTIG CHRCKKK : ",RQDCheck)



 ///

//ABC RATING
this.service.currentCrownN_mod.subscribe(crownN_mod=> this.crownN_mod = crownN_mod);
this.service.currentSide1N_mod.subscribe(side1N_mod=> this.side1N_mod = side1N_mod);
this.service.currentSide2N_mod.subscribe(side2N_mod=> this.side2N_mod = side2N_mod);
this.service.currentHwN_mod.subscribe(hwN_mod=> this.hwN_mod = hwN_mod);
this.service.currentFwN_mod.subscribe(fwN_mod=> this.fwN_mod = fwN_mod);

this.service.currentTimeFactor_crown.subscribe(timeFactor_crown => this.timeFactor_crown = timeFactor_crown);
this.service.currentTimeFactor_hw.subscribe(timeFactor_hw => this.timeFactor_hw = timeFactor_hw);
this.service.currentTimeFactor_fw.subscribe(timeFactor_fw => this.timeFactor_fw = timeFactor_fw);
this.service.currentTimeFactor_sw1.subscribe(timeFactor_sw1 => this.timeFactor_sw1 = timeFactor_sw1);
this.service.currentTimeFactor_sw2.subscribe(timeFactor_sw2 => this.timeFactor_sw2 = timeFactor_sw2);

this.service.currentFaultFactor_crown.subscribe(faultFactor_crown => this.faultFactor_crown = faultFactor_crown);
this.service.currentFaultFactor_hw.subscribe(faultFactor_hw => this.faultFactor_hw = faultFactor_hw);
this.service.currentFaultFactor_fw.subscribe(faultFactor_fw => this.faultFactor_fw = faultFactor_fw);
this.service.currentFaultFactor_sw1.subscribe(faultFactor_sw1 => this.faultFactor_sw1 = faultFactor_sw1);
this.service.currentFaultFactor_sw2.subscribe(faultFactor_sw2 => this.faultFactor_sw2 = faultFactor_sw2);

this.service.currentCrown_Afactor_modified.subscribe(crown_Afactor_modified => this.crown_Afactor_modified = crown_Afactor_modified);
this.service.currentCrown_Bfactor_modified.subscribe(crown_Bfactor_modified => this.crown_Bfactor_modified = crown_Bfactor_modified);
this.service.currentCrown_Cfactor_modified.subscribe(crown_Cfactor_modified => this.crown_Cfactor_modified = crown_Cfactor_modified);

this.service.currentSide1_Afactor_modified.subscribe(side1_Afactor_modified => this.side1_Afactor_modified = side1_Afactor_modified);
this.service.currentSide1_Bfactor_modified.subscribe(side1_Bfactor_modified => this.side1_Bfactor_modified = side1_Bfactor_modified);
this.service.currentSide1_Cfactor_modified.subscribe(side1_Cfactor_modified => this.side1_Cfactor_modified = side1_Cfactor_modified);

this.service.currentSide2_Afactor_modified.subscribe(side2_Afactor_modified => this.side2_Afactor_modified = side2_Afactor_modified);
this.service.currentSide2_Bfactor_modified.subscribe(side2_Bfactor_modified => this.side2_Bfactor_modified = side2_Bfactor_modified);
this.service.currentSide2_Cfactor_modified.subscribe(side2_Cfactor_modified => this.side2_Cfactor_modified = side2_Cfactor_modified);

this.service.currentFw_Cfactor_modified.subscribe(fw_Cfactor_modified => this.fw_Cfactor_modified = fw_Cfactor_modified);
this.service.currentFw_Afactor_modified.subscribe(fw_Afactor_modified => this.fw_Afactor_modified = fw_Afactor_modified);
this.service.currentFw_Bfactor_modified.subscribe(fw_Bfactor_modified => this.fw_Bfactor_modified = fw_Bfactor_modified);

this.service.currentHw_Afactor_modified.subscribe(hw_Afactor_modified => this.hw_Afactor_modified = hw_Afactor_modified);
this.service.currentHw_Bfactor_modified.subscribe(hw_Bfactor_modified => this.hw_Bfactor_modified = hw_Bfactor_modified);
this.service.currentHw_Cfactor_modified.subscribe(hw_Cfactor_modified => this.hw_Cfactor_modified = hw_Cfactor_modified);



while(ABCRating.length!=0){
  ABCRating.pop()
}
while(ABCRating2.length!=0){
  ABCRating2.pop()
}
if(this.abcService.getAbcStopes().includes("Crown"))
{
  let data0={
    Stope_Surface:"Crown",
    Stress_Factor_A:      this.crown_Afactor_modified,
    Joint_Defect_Factor_B:this.crown_Bfactor_modified,
    Gravity_Factor_C :   this.crown_Cfactor_modified,
 

  }
  let data1={
    Stope_Surface:"Crown",
    Time_Factor_T : this.timeFactor_crown,
    Fault_Factor_F :this.faultFactor_crown,
    Stability_Number_N :this.crownN_mod,
    Outcome:        `${this.sService.getCrownOutcome()}`

}
  ABCRating2.push(data1);

  ABCRating.push(data0);
// cc=cc+`-----------------------------------------------\nCrown Stability Number(N) : ${this.abcService.getCrownN()}\n
// Stress Factor(A) : ${this.abcService.getCrownA()}      \n
// Joint Defect Factor(B) : ${this.abcService.getCrownB()}\n
// Gravity Factor(C) : ${this.abcService.getCrownC()}\n
// Time Factor(T) : ${this.abcService.getCrownT()}\n
// Fault Factor(F) : ${this.abcService.getCrownF()}\n

// Qvalue : ${this.qService.getCrownQ()} \n \n

// Crown Outcome: ${this.sService.getCrownOutcome()}\n`

}
if(this.abcService.getAbcStopes().includes("HW"))
{
// cc=cc+`-----------------------------------------------\nHW Stability Number(N) : ${this.abcService.getHangN()}\n
// Stress Factor(A) : ${this.abcService.getHangA()}      \n
// Joint Defect Factor(B) : ${this.abcService.getHangB()}\n
// Gravity Factor(C) : ${this.abcService.getHangC()}\n
// Time Factor(T) : ${this.abcService.getHangT()}\n
// Fault Factor(F) : ${this.abcService.getHangF()}\n

// Qvalue : ${this.qService.getHangQ()} \n \n


// HW Outcome: ${this.sService.getHWOutcome()}\n`
let data0={
  Stope_Surface:"Hangingwall",
  Stress_Factor_A:      this.hw_Afactor_modified,
  Joint_Defect_Factor_B:this.hw_Bfactor_modified,
  Gravity_Factor_C :   this.hw_Cfactor_modified,


}
let data1={
  Stope_Surface:"Hangingwall",
  Time_Factor_T : this.timeFactor_hw,
  Fault_Factor_F :this.faultFactor_hw,
  Stability_Number_N :this.hwN_mod,
  Outcome:         `${this.sService.getHWOutcome()}`

}
ABCRating2.push(data1);
ABCRating.push(data0);
}
if(this.abcService.getAbcStopes().includes("FW"))
{
  let data0={
    Stope_Surface:"Footingwall",
    Stress_Factor_A:      this.fw_Afactor_modified,
    Joint_Defect_Factor_B:this.fw_Bfactor_modified,
    Gravity_Factor_C :   this.fw_Cfactor_modified,

  

  }
  
  let data1={
    Stope_Surface:"Footingwall",
    Time_Factor_T : this.timeFactor_fw,
    Fault_Factor_F :this.faultFactor_fw,
    Stability_Number_N :this.fwN_mod,
    Outcome:        `${this.sService.getFWOutcome()}`
  
  }
  ABCRating2.push(data1);
  ABCRating.push(data0);
// cc=cc+`-----------------------------------------------\nFW Stability Number(N) : ${this.abcService.getFootN()}\n
// Stress Factor(A) : ${this.abcService.getFootA()}      \n
// Joint Defect Factor(B) : ${this.abcService.getFootB()}\n
// Gravity Factor(C) : ${this.abcService.getFootC()}\n
// Time Factor(T) : ${this.abcService.getFootT()}\n
// Fault Factor(F) : ${this.abcService.getFootF()}\n

// Qvalue : ${this.qService.getFootQ()} \n \n

// FW Outcome: ${this.sService.getFWOutcome()}\n`
}
if(this.abcService.getAbcStopes().includes("SW1"))
{
// cc=cc+`-----------------------------------------------\nSW1 Stability Number(N) : ${this.abcService.getSide1N()}\n
// Stress Factor(A) : ${this.abcService.getSide1A()}      \n
// Joint Defect Factor(B) : ${this.abcService.getSide1B()}\n
// Gravity Factor(C) : ${this.abcService.getSide1C()}\n
// Time Factor(T) : ${this.abcService.getSide1T()}\n
// Fault Factor(F) : ${this.abcService.getSide1F()}\n

// Qvalue : ${this.qService.getSide1Q()} \n \n

// SW1 Outcome: ${this.sService.getSW1Outcome()}\n`
let data0={
  Stope_Surface:"Sidewall 1",
  Stress_Factor_A:      this.side1_Afactor_modified,
  Joint_Defect_Factor_B:this.side1_Bfactor_modified,
  Gravity_Factor_C :   this.side1_Cfactor_modified,


}
let data1={
  Stope_Surface:"Sidewall 1",
  Time_Factor_T : this.timeFactor_sw1,
  Fault_Factor_F :this.faultFactor_sw1,
  Stability_Number_N :this.side1N_mod,
  Outcome: this.sService.getSW1Outcome()

}
ABCRating2.push(data1);
ABCRating.push(data0);
}
if(this.abcService.getAbcStopes().includes("SW2"))
{
//cc=cc+`-----------------------------------------------\nSW2 Stability Number(N) : ${this.abcService.getSide2N()}\n
// Stress Factor(A) : ${this.abcService.getSide2A()}      \n
// Joint Defect Factor(B) : ${this.abcService.getSide2B()}\n
// Gravity Factor(C) : ${this.abcService.getSide2C()}\n
// Time Factor(T) : ${this.abcService.getSide2T()}\n
// Fault Factor(F) : ${this.abcService.getSide2F()}\n

// Qvalue : ${this.qService.getSide2Q()} \n \n

// SW2 Outcome: ${this.sService.getSW2Outcome()}\n`
let data0={
  Stope_Surface:"Sidewall 2",
  Stress_Factor_A:      this.side2_Afactor_modified,
  Joint_Defect_Factor_B:this.side2_Bfactor_modified,
  Gravity_Factor_C :   this.side2_Cfactor_modified,


}
let data1={
  Stope_Surface:"Sidewall 2",
  Time_Factor_T : this.timeFactor_sw2,
  Fault_Factor_F :this.faultFactor_sw2,
  Stability_Number_N :this.side2N_mod,
  Outcome: `${this.sService.getSW2Outcome()}`

}
ABCRating2.push(data1);

ABCRating.push(data0);
}
let cautionCheck='';
let cautionGet=this.sService.getCaution()
if(isUndefined(cautionGet)){
  cautionCheck=''
}
else
{
  cautionCheck=cautionGet
}
 ///
 console.log("ABC  RATTTTING   NNNN : ",ABCRating)
    let docDefinition = {  
        
      content:  [
        {text: '\n                                  Open Stope Stability Report\n',style:'header1'},
      { text: '\n\n\nDate: _________________________\n\nMine: _________________________\n\nLevel: _________________________\n\nNote: _________________________', style: 'header2' },'\n\nHydraulic Radius\n',
      table(HrValues, ['Type','Crown','Hangingwall'	,'Footwall','Sidewall']),
       "\nQ results\n",
      table(QModifiedRange, ['Modified_Q_range'	,'Category']),
      "\n\n",
      table(QModifiedRating, ['Stope_Surface','QRes','Rating']),
      "\nRQD results\n",
      table(RQDRange, ['Stope_Range','Rating']),
      "\n\n",
      table(RQDRating, ['Stope_Surface','RQD','Rating']),
      "\nABC Factors\n",
      table(ABCRating, [
        'Stope_Surface'
        ,'Stress_Factor_A',
  'Joint_Defect_Factor_B',
  'Gravity_Factor_C',

      ]),
      "\nAdjustment Factors and Outcome\n",      table(ABCRating2, [
        'Stope_Surface',
  'Time_Factor_T',
  'Fault_Factor_F',
  'Stability_Number_N',
  'Outcome'

      ]),"\n\n",`${cautionCheck}`



    //  table(externalDataRetrievedFromServer, ['name', 'age','int'])
      ],
      styles: {
        header2: {
          fontSize: 15,
          bold: true
        },
        header1: {
          fontSize: 22,
          bold: true,
          alignment: 'center'

        },
    } 
  }
   console.log(" before ..")
    pdfMake.createPdf(docDefinition).download('Open Stope Stability Results.pdf'); 
    console.log(" After ..")

    this.dialogRef.close(true);
   





// let cc=`Hydraulic Radius Calculation \n\n Crown : ${this.hrService.getcrown()} \n Hangwall : ${this.hrService.gethang()}\n Footwall : ${this.hrService.getfoot()}\n Sidewall : ${this.hrService.getside()} \n

//`

   
  }
  
  shareData() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.dialog.open(DataSharingComponent, dialogConfig);
  }
  
  ngOnInit(): void {
 
    // this.HrValues
    // this.service.currentCrownP.subscribe(crownP => this.crownP = crownP)
    // this.service.currentCrownA.subscribe(crownA => this.crownA = crownA)
    // this.service.currentFootP.subscribe(footP => this.footP = footP)
    // this.service.currentFootA.subscribe(footA => this.footA = footA)
    // this.service.currentHangP.subscribe(hangP => this.hangP = hangP)
    // this.service.currentHangA.subscribe(hangA => this.hangA = hangA)
    // this.service.currentSideP.subscribe(sideP => this.sideP = sideP)
    // this.service.currentSideA.subscribe(sideA => this.sideA = sideA)
  }

}
