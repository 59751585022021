<button tabindex="-1" mat-button class="close" 
(click)="onClick()" style="font-size:1.5vw">x
</button>


<div mat-dialog-content>

  <h6>CAUTION :The stope you are checking is not stable. For stope to be stable please ensure that all stope surfaces are stable. </h6>

  <div mat-dialog-actions>
    
    <div class="jrh">
</div>

</div>
