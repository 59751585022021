<button tabindex="-1" mat-button class="close" 
(click)="onClick()" style="font-size:1.5vw"> X
</button>

<!-- <h1 mat-dialog-title>Select Jr that matches your condition</h1> -->

<div mat-dialog-content>

  <!-- <h6><i>1) Rock-wall contact, and rock-wall contact before 10 cm of shear</i></h6> -->

  <div mat-dialog-actions>
    
    <div class="jrh">
      <h3>Note: The stope you are checking is stable because all stope surfaces are stable. </h3>
    
    </div>
  </div>
</div>
