<ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <a class="nav-link"  data-toggle="pill" role="tab" routerLink="/hr" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" style="padding-left:3em;" aria-controls="pills-hr" style="padding-right: 1em;"><i class="fab fa-codepen"></i>{{sFactorTitle}}</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link"  data-toggle="pill" role="tab" routerLink="/qvalue" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" aria-controls="pills-qvalue" aria-selected="false"><i class="fas fa-chart-pie"></i>{{qValueTitle}}</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link"  data-toggle="pill" role="tab" routerLink="/stress" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" aria-controls="pills-stress" aria-selected="false"><i class="fas fa-exchange-alt"></i>{{stressTitle}}</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" data-toggle="pill" role="tab" routerLink="/factors" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" aria-controls="pills-factors" aria-selected="false"><i class="fas fa-grip-horizontal"></i>{{abcFactorsTitle}}</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" data-toggle="pill" role="tab" routerLink="/stability" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" aria-controls="pills-stability" aria-selected="false"><i class="fas fa-calculator"></i>{{stabilityAnalysisTitle}}</a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" data-toggle="pill" role="tab" routerLink="/outcome" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" aria-controls="pills-outcome" aria-selected="false"><i class="fas fa-list-alt"></i>{{outcomeTitle}}</a>
  </li>
</ul>
