import { Component, OnInit, Inject,AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ForAllService } from '../services/for-all.service';
import { ChartOptions, ChartType, ChartDataSets, Chart, ChartLegendLabelOptions, ChartData } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import {PredictionService} from "../factors-res/predictesult.service";
import {Predict} from "../models/predict.model";
import {Subscription} from "rxjs";
import { abcfactor } from '../services/abcfactor.service'
import {stabilityService} from '../services/stability.service'
import {hrvalues} from '../services/hrvalues.service'
import {qvaluefactor} from '../services/qvalue-factor.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from "@angular/material/dialog";
import { Overlay} from '@angular/cdk/overlay';


import {allResetValues} from '../services/All-Reset-Form.service'
import { isUndefined } from 'util';
import { BoundElementProperty, CompileShallowModuleMetadata } from '@angular/compiler';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { TouchSequence } from 'selenium-webdriver';
export interface DialogData {
  coreL: number;
  totalL: number;
}

@Component({
  selector: 'app-stability',
  templateUrl: './stability.component.html',
  styleUrls: ['./stability.component.scss']
})

export class StabilityComponent implements OnInit , AfterViewInit {
  prediction: Predict = this.predictionService.getPrediction();
  subscription: Subscription;
  public stableCount :number;
  public hrCrown: number;
  public dialogConfig;
  public hrFoot: number;
  public hrHang: number;
  public hrSide: number;
  public cautionValue:string;
  public crownN: number;
  public side1N: number;
  public side2N: number;
  public hwN: number;
  public fwN: number;
public nnData: ChartDataSets[]
  public crownN_mod: number;
  public side1N_mod: number;
  public side2N_mod: number;
  public hwN_mod: number;
  public fwN_mod: number;

  public yAxis: Object;


  public scatterChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'The Refined Stability Graph',
      padding:7,
  },
    scales: {
      gridLines: {display:true,
      drawBorder:true,
      drawTicks:true,
      color:'#fdfdfd'},
      xAxes:[{type:'logarithmic',
      ticks: {
        fontSize:10,
        padding:0,
        min:1,
        max:100,
        callback: function (value, index, values) {
          // //////console.log("\n\n THISSSS -> ",Number(value.toString()))
          return Number(value.toString());
        }
      },
      scaleLabel: {
        display: true,
        labelString:"Hydraulic Radius, HR (m)",
        padding:0,
        fontSize:12
      }
    }],
    yAxes:[{type:'logarithmic',
      ticks: {
        fontSize:10,
        padding:0,
        min:0.01,
        max:1000,
      },
      scaleLabel: {
        display: true,
        labelString:"Modified Stability Number, N'",
        padding:0,
        fontSize:12
      }
    }]
  },
  legend: {
  
    position:'right',
    display: true,
    labels: {
      boxWidth:20,
        fontSize:10,
        fontColor:'black',
        generateLabels: function(chart) {
          var data = chart.data;
          //////console.log("DAT NOW ",data)
      
         const ded= ['rgba(34,101, 86,0.5)','rgba(0,0,0,0.5)','rgba(255,0,0,0.45)','red','green','blue','black','#8a00d4']
          const barLegends = ['Stable zone', 'Unstable zone', 'Failure zone','Crown','Hangingwall','Footwall','Sidewall 1','Sidewall 2'];

          //////console.log("de->",data)
          if (barLegends.length && data.datasets.length) {
              return barLegends.map(function(label, i) {
                  var meta = chart.getDatasetMeta(0);
                  var ds = data.datasets[0];
            

                  return {
                    
                      text: label + " " ,
                       fillStyle: ded[i],
                      PointStyle:'circle',
                      // hidden:  meta.data[i].hidden,
                      index: i
                  };
              });
          } else {
              return [];
          }     
       }
}
  }
  // legend:{
  //   position:'right',
  //   labels: {
  //     boxWidth:20,
  //     fontSize:10
  //   }
  // }
  };
  public ChartLegend = true;

  public extendedChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'The Extended Stability Graph after Trueman&Mawdesley (2003)',
      padding:7,
  },
    scales: {
      gridLines: {display:true,
      drawBorder:true,
      drawTicks:true,
      color:'#000000'},
      xAxes:[{type:'logarithmic',
      ticks: {
        min:1,
        max:100,
        callback: function (value, index, values) {
          return Number(value.toString());
        }
      },
      scaleLabel: {
        display: true,
        labelString:"Hydraulic Radius, HR (m)",
        fontSize:12,
        padding:0
      }
    }],
    yAxes:[{type:'logarithmic',
      ticks: {
        min:0.001,
        max:1000,
      },
      scaleLabel: {
        display: true,
        labelString:"Original Stability Number, N",
        fontSize:12,
        padding:0
      },      
  
    }]
  },
  legend: {
  
    position:'right',
    display: true,
    labels: {
      // usePointStyle: true,

      boxWidth:20,
        fontSize:10,
        fontColor:'black',
        generateLabels: function(chart) {
          var data = chart.data;
          //////console.log("DAT NOW ",data)
      
         const ded= ['rgba(34,101, 86,0.5)','rgba(0,0,0,0.5)','rgba(255,0,0,0.45)','red','green','blue','black','#8a00d4']
         const barLegends = ['Stable zone', 'Unstable zone', 'Failure zone','Crown','Hangingwall','Footwall','Sidewall 1','Sidewall 2'];

          //////console.log("de->",data)
          if (barLegends.length && data.datasets.length) {
              return barLegends.map(function(label, i) {
                  var meta = chart.getDatasetMeta(0);
                  var ds = data.datasets[0];
            

                  return {
                    
                      text: label + " " ,
                       fillStyle: ded[i],

                      // hidden:  meta.data[i].hidden,
                      index: i
                  };
              });
          } else {
              return [];
          }     
       }
}
  }
};

public modifiedChartOptions: ChartOptions = {
  responsive: true,
  title: {
    display: true,
    text: "The Potvin' Stability Graph",
    padding:7,
},
maintainAspectRatio: false,
scales: {
    gridLines: {display:true,
    drawBorder:true,
    drawTicks:true,
    color:'#000000'},
    xAxes:[{type:'linear',
    ticks: {
      min:0,
      max:25,
      stepSize:5,
      callback: function (value, index, values) {
        return Number(value.toString());
      }
    },
    scaleLabel: {
      display: true,
      labelString:"Hydraulic Radius, HR (m)",
    }
  }],
  yAxes:[{type:'logarithmic',
    ticks: {
      min:0.1,
      max:1000,
    },
    scaleLabel: {
      display: true,
      labelString:"Modified Stability Number, N'",
    }
  }]
},
legend: {
  
  position:'right',
  display: true,
  labels: {
    boxWidth:20,
      fontSize:10,
      fontColor:'black',
      generateLabels: function(chart) {
        var data = chart.data;
        //////console.log("DAT NOW ",data)
    
       const ded= ['rgba(34,101, 86,0.5)','rgba(0,0,0,0.5)','rgba(255,0,0,0.45)','red','green','blue','black','#8a00d4']
       const barLegends = ['Stable zone', 'Unstable zone', 'Failure zone','Crown','Hangingwall','Footwall','Sidewall 1','Sidewall 2'];
        // const ded2=['circle','triangle','rectRot','circle','circle'];
        //////console.log("de->",data)
        if (barLegends.length && data.datasets.length) {
            return barLegends.map(function(label, i) {
                var meta = chart.getDatasetMeta(0);
                var ds = data.datasets[0];
          

                return {
                  
                    text: label + " " ,
                     fillStyle: ded[i],
                     pointStyle:'triangle',
                    
                    // hidden:  meta.data[i].hidden,
                    index: i
                };
            });
        } else {
            return [];
        }     
     }
     ,
     fontStyle:'circle'
}
}
}; 
public sensitivityChartOptions: ChartOptions  = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes:[{ 
    scaleLabel: {
      display: true,
      labelString:"Hydraulic Radius, HR (m)",
      fontSize:11,
      padding:0
    }
  }],
  yAxes:[{
    ticks: {
      fontSize:10,
      padding:0,
      min:0,
      max:100,
    },
    scaleLabel: {
     
      display: true,
      labelString:"Probabilistic Stability Prediction (%)",
      fontSize:10, 
      padding:0
    }
  }]
  
  }
  ,legend: {
  
    display: true,
    labels: {
      boxWidth:20,
        fontSize:10,
        fontColor:'black',
        generateLabels: function(chart) {
          var data = chart.data;
          //////console.log("DAT NOW ",data)
      
         const ded= ['rgba(34,101, 86,0.5)','rgba(0,0,0,0.5)','rgba(255,0,0,0.45)','red','green','blue','black','#8a00d4']
         const barLegends = ['STABLE', 'UNSTABLE', 'FAILURE'];
  
          //////console.log("de->",data)
          if (barLegends.length && data.datasets.length) {
              return barLegends.map(function(label, i) {
                  var meta = chart.getDatasetMeta(0);
                  var ds = data.datasets[0];
            
  
                  return {
                    
                      text: label + " " ,
                       fillStyle: ded[i],
                      
                      // hidden:  meta.data[i].hidden,
                      index: i
                  };
              });
          } else {
              return [];
          }     
       }
  },
  
  }
};
public dataSetCrown(data1){

  for(let i=5, j=0;i>=0;i--,j++){
    this.crownChartData[0].data[j]=(data1["inc"][i][0][0]*100).toFixed(2)as unknown as number;
  }
  
  
  //for dec stable
  for(let i=0,j=6;i<6;i++,j++){
    
    this.crownChartData[0].data[j]=(data1["dec"][i][0][0]*100).toFixed(2)as unknown as number;
  }
  
  
  
  // first 7 elements (dec elements) for charData of unstable
  
  for(let i=5,j=0;i>=0;i--,j++){
    this.crownChartData[1].data[j]=(data1["inc"][i][0][1]*100).toFixed(2)as unknown as number;
  }
  
  //for dec unstable
  for(let i=0,j=6;i<6;i++,j++){
    this.crownChartData[1].data[j]=(data1["dec"][i][0][1]*100).toFixed(2)as unknown as number;
  }
  
 //for inc failure

  for(let i=5, j=0;i>=0;i--,j++){
    this.crownChartData[2].data[j]=(data1["inc"][i][0][2]*100).toFixed(2)as unknown as number;
  }
  
  //for dec failure
  for(let i=0,j=6;i<6;i++,j++){
    this.crownChartData[2].data[j]=(data1["dec"][i][0][2]*100).toFixed(2)as unknown as number;
  }
  

}

public dataSetHang(data1){
  for(let i=5, j=0;i>=0;i--,j++){
    this.hwChartData[0].data[j]=(data1["inc"][i][0][0]*100).toFixed(2)as unknown as number;
  }
  
  
  //for dec stable
  for(let i=0,j=6;i<6;i++,j++){
    
    this.hwChartData[0].data[j]=(data1["dec"][i][0][0]*100).toFixed(2)as unknown as number;
  }
  
  
  
  // first 7 elements (dec elements) for charData of unstable
  
  for(let i=5,j=0;i>=0;i--,j++){
    this.hwChartData[1].data[j]=(data1["inc"][i][0][1]*100).toFixed(2)as unknown as number;
  }
  
  //for dec unstable
  for(let i=0,j=6;i<6;i++,j++){
    this.hwChartData[1].data[j]=(data1["dec"][i][0][1]*100).toFixed(2)as unknown as number;
  }
  
 //for inc failure

  for(let i=5, j=0;i>=0;i--,j++){
    this.hwChartData[2].data[j]=(data1["inc"][i][0][2]*100).toFixed(2)as unknown as number;
  }
  
  //for dec failure
  for(let i=0,j=6;i<6;i++,j++){
    this.hwChartData[2].data[j]=(data1["dec"][i][0][2]*100).toFixed(2)as unknown as number;
  }
    

 
  
  
  }

  public dataSetFoot(data1){
    for(let i=5, j=0;i>=0;i--,j++){
      this.fwChartData[0].data[j]=(data1["inc"][i][0][0]*100).toFixed(2)as unknown as number;
    }
    
    
    //for dec stable
    for(let i=0,j=6;i<6;i++,j++){
      
      this.fwChartData[0].data[j]=(data1["dec"][i][0][0]*100).toFixed(2)as unknown as number;
    }
    
    
    
    // first 7 elements (dec elements) for charData of unstable
    
    for(let i=5,j=0;i>=0;i--,j++){
      this.fwChartData[1].data[j]=(data1["inc"][i][0][1]*100).toFixed(2)as unknown as number;
    }
    
    //for dec unstable
    for(let i=0,j=6;i<6;i++,j++){
      this.fwChartData[1].data[j]=(data1["dec"][i][0][1]*100).toFixed(2)as unknown as number;
    }
    
   //for inc failure

    for(let i=5, j=0;i>=0;i--,j++){
      this.fwChartData[2].data[j]=(data1["inc"][i][0][2]*100).toFixed(2)as unknown as number;
    }
    
    //for dec failure
    for(let i=0,j=6;i<6;i++,j++){
      this.fwChartData[2].data[j]=(data1["dec"][i][0][2]*100).toFixed(2)as unknown as number;
    }
    

    
    } 
public dataSetSide2(data1){
  for(let i=5, j=0;i>=0;i--,j++){
    this.side2ChartData[0].data[j]=(data1["inc"][i][0][0]*100).toFixed(2)as unknown as number;
  }
  
  
  //for dec stable
  for(let i=0,j=6;i<6;i++,j++){
    
    this.side2ChartData[0].data[j]=(data1["dec"][i][0][0]*100).toFixed(2)as unknown as number;
  }
  
  
  
  // first 7 elements (dec elements) for charData of unstable
  
  for(let i=5,j=0;i>=0;i--,j++){
    this.side2ChartData[1].data[j]=(data1["inc"][i][0][1]*100).toFixed(2)as unknown as number;
  }
  
  //for dec unstable
  for(let i=0,j=6;i<6;i++,j++){
    this.side2ChartData[1].data[j]=(data1["dec"][i][0][1]*100).toFixed(2)as unknown as number;
  }
  
 //for inc failure

  for(let i=5, j=0;i>=0;i--,j++){
    this.side2ChartData[2].data[j]=(data1["inc"][i][0][2]*100).toFixed(2)as unknown as number;
  }
  
  //for dec failure
  for(let i=0,j=6;i<6;i++,j++){
    this.side2ChartData[2].data[j]=(data1["dec"][i][0][2]*100).toFixed(2)as unknown as number;
  }
  
       ////console.log("CHECK again:: ",this.side2ChartData)
      
      }
      public sumData(n1 ,n2){
          ////console.log("inside funtiom :  ",n1, "+", n2,": ",(+n1 + +n2))
          return +n1 + +n2
      } 
      public getLabels(tempHr: number){
        ////console.log("val : ",tempHr)
        let hrTemp=tempHr
        // if(hrTemp <9){
        //lower bound 3 wont meet as (9-7)=2
        // 7 dex , org , 7 inc numbers
        const arr=[]
        let n=hrTemp as number
        // while(n>=3){
        // arr.push(n)
        // n--;
        // }
        for(let i=7 ;i>=0;i--){
          n=hrTemp-i
          ////console.log("n value 1st ", n)

          if(n<3  ||  n>25)
          {
        
          }
          else
          {
            arr.push(n)
          }
        }
        ////console.log("arr 1->",arr)
        
        if(hrTemp<3  ||  hrTemp>25)
          {
        
          }
          else
          {
            arr.push(hrTemp)
          }
          ////console.log("arr 2->",arr)
        
          n=hrTemp as number
        
          for(let i=1 ;i<=7;i++){
            ////console.log("NOOOOO : <>",this.sumData(hrTemp,i))
            let yy=this.sumData(hrTemp,i);
            if(yy<3  ||  yy>25)
            {
          
            }
            else
            {
              ////console.log("n value inside else ", yy)

                arr.push(yy)
            }
          } 
          ////console.log("\n\n\narr final->",arr)

          return arr;
        
      }
public dataSetSide1(data1){
        // console.log("CHECK:: sw1 ",data1)
        //for inc  stable
        for(let i=5, j=0;i>=0;i--,j++){
          this.side1ChartData[0].data[j]=(data1["inc"][i][0][0]*100).toFixed(2)as unknown as number;
        }
        
        
        //for dec stable
        for(let i=0,j=6;i<6;i++,j++){
          
          this.side1ChartData[0].data[j]=(data1["dec"][i][0][0]*100).toFixed(2)as unknown as number;
        }
        
        
        
        // first 7 elements (dec elements) for charData of unstable
        
        for(let i=5,j=0;i>=0;i--,j++){
          this.side1ChartData[1].data[j]=(data1["inc"][i][0][1]*100).toFixed(2)as unknown as number;
        }
        
        //for dec unstable
        for(let i=0,j=6;i<6;i++,j++){
          this.side1ChartData[1].data[j]=(data1["dec"][i][0][1]*100).toFixed(2)as unknown as number;
        }
        
       //for inc failure

        for(let i=5, j=0;i>=0;i--,j++){
          this.side1ChartData[2].data[j]=(data1["inc"][i][0][2]*100).toFixed(2)as unknown as number;
        }
        
        //for dec failure
        for(let i=0,j=6;i<6;i++,j++){
          this.side1ChartData[2].data[j]=(data1["dec"][i][0][2]*100).toFixed(2)as unknown as number;
        }
        
        } 
public sensitivityChartType: ChartType = 'bar';
public hwChartLabels: Label[] =    [];
public crownChartLabels: Label[] = [];
public fwChartLabels: Label[] =    [];
public side1ChartLabels: Label[] = [];
public side2ChartLabels: Label[] = [];

// public crownChartLabels: Label[] = [(this.hrService.getcrown() as unknown as number -7 ) .toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number-6).toFixed(2) as unknown as string,(this.hrService.getcrown() as unknown as number-5).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number-4).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number-3).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number-2).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number-1).toFixed(2) as unknown as string,(this.hrService.getcrown() as unknown as number).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number+1).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number+2).toFixed(2) as unknown as string,(this.hrService.getcrown() as unknown as number+3).toFixed(2) as unknown as string,(this.hrService.getcrown() as unknown as number+4).toFixed(2) as unknown as string,(this.hrService.getcrown() as unknown as number+5).toFixed(2) as unknown as string, (this.hrService.getcrown() as unknown as number+6).toFixed(2) as unknown as string,(this.hrService.getcrown() as unknown as number+7).toFixed(2) as unknown as string];
// public hwChartLabels: Label[] =    [(this.hrService.gethang()  as unknown as number -7 ) .toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number-6).toFixed(2) as unknown as string,(this.hrService.gethang() as unknown as number-5).toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number-4).toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number-3).toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number-2).toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number-1).toFixed(2) as unknown as string,      (this.hrService.gethang() as unknown as number).toFixed(2) as unknown as string,(this.hrService.gethang() as unknown as number+1).toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number+2).toFixed(2) as unknown as string,(this.hrService.gethang() as unknown as number+3).toFixed(2) as unknown as string,(this.hrService.gethang() as unknown as number+4).toFixed(2) as unknown as string,(this.hrService.gethang() as unknown as number+5).toFixed(2) as unknown as string, (this.hrService.gethang() as unknown as number+6).toFixed(2) as unknown as string,(this.hrService.gethang() as unknown as number+7).toFixed(2) as unknown as string              ];
// public fwChartLabels: Label[] =    [(this.hrService.getfoot()  as unknown as number -7 ) .toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number-6).toFixed(2) as unknown as string,(this.hrService.getfoot() as unknown as number-5).toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number-4).toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number-3).toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number-2).toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number-1).toFixed(2) as unknown as string,      (this.hrService.getfoot() as unknown as number).toFixed(2) as unknown as string,(this.hrService.getfoot() as unknown as number+1).toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number+2).toFixed(2) as unknown as string,(this.hrService.getfoot() as unknown as number+3).toFixed(2) as unknown as string,(this.hrService.getfoot() as unknown as number+4).toFixed(2) as unknown as string,(this.hrService.getfoot() as unknown as number+5).toFixed(2) as unknown as string, (this.hrService.getfoot() as unknown as number+6).toFixed(2) as unknown as string,(this.hrService.getfoot() as unknown as number+7).toFixed(2) as unknown as string              ];
// public side1ChartLabels: Label[] = [(this.hrService.getside()  as unknown as number -7 ) .toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-6).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number-5).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-4).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-3).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-2).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-1).toFixed(2) as unknown as string,      (this.hrService.getside() as unknown as number).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+1).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number+2).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+3).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+4).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+5).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number+6).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+7).toFixed(2) as unknown as string              ];
// public side2ChartLabels: Label[] = [(this.hrService.getside()  as unknown as number -7 ) .toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-6).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number-5).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-4).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-3).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-2).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number-1).toFixed(2) as unknown as string,      (this.hrService.getside() as unknown as number).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+1).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number+2).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+3).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+4).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+5).toFixed(2) as unknown as string, (this.hrService.getside() as unknown as number+6).toFixed(2) as unknown as string,(this.hrService.getside() as unknown as number+7).toFixed(2) as unknown as string              ];
public sensitivityChartLegend = true;

public crownChartData: ChartDataSets[] = [
  { data: [], label: 'stable', stack: 'a'},
  { data: [], label: 'unstable', stack: 'a' },
  { data: [], label: 'failure', stack: 'a' }
    ];
public hwChartData: ChartDataSets[] = [
  { data: [], label: 'stable', stack: 'a' },
  { data: [], label: 'unstable', stack: 'a' },
  { data: [], label: 'failure', stack: 'a' }
  ];
public fwChartData: ChartDataSets[] = [
  { data: [], label: 'stable', stack: 'a' },
  { data: [], label: 'unstable', stack: 'a' },
  { data: [], label: 'failure', stack: 'a' }
  ];
public side1ChartData: ChartDataSets[] = [
  { data: [], label: 'stable', stack: 'a' },
  { data: [], label: 'unstable', stack: 'a' },
  { data: [], label: 'failure', stack: 'a' }
  ];
public side2ChartData: ChartDataSets[] = [
  { data: [], label: 'stable', stack: 'a' },
  { data: [], label: 'unstable', stack: 'a' },
  { data: [], label: 'failure', stack: 'a' }
  ];

public sensitivityChartColors: Color[] = [
  {backgroundColor: 'rgba(34,101, 86,0.5)'},
  {backgroundColor:'rgba(0,0,0,0.5)'},
  {backgroundColor:'rgba(255,0,0,0.45)'}
];

  constructor(private router: Router, private route: ActivatedRoute, private overlay: Overlay, private service: ForAllService, 
    private predictionService: PredictionService, public dialog: MatDialog,private abcService:abcfactor,private sService:stabilityService,private hrService:hrvalues,private qservice:qvaluefactor) { }

    public chartLabels: Label = ['d1','d2','d3','d4','d5']
    ;

  public refinedChartData: ChartDataSets[] = [
    { type: 'line',
    data: [{x:0.5, y:0.0766},{x:1, y:1001},{x:34, y:1023.5}],
    fill: 1,
    pointStyle:'line',
    label: "Stable zone"},
    { type: 'line',
      data: [{x:1, y:0.0766},{x:34, y:1023.5}, {x:56, y:1003.132}],
      fill: 2,
      pointStyle:'line',
      label: "Unstable zone"
    },
    { type: 'line',
      data: [{x:1, y:0.02},{x:56, y:1003.132},{x:100, y:1002}],
      fill: true,
      pointStyle:'line',
      label: "Failure zone"
    },
    //{x:1, y:0.02},{x:1, y:0.5}
    { type: 'scatter',
    
    pointStyle:[],
    pointBorderColor:[],
      data: [],
      label:'Stope Surfaces',
      fill: true,
      pointRadius:5,
    },
    
  ];

  public modifiedChartData: ChartDataSets[] = [
    { type: 'line',
    data: [{x:0,y:0.1},{x:0,y:1000},{x:25,y:1000},{x:25, y:600}, {x:24.7155, y:589.511},{x:19.7368, y:307.981}],
    fill: 1,
    pointStyle:'line',
    label: "Stable zone"
    },
    { type: 'line',
      data: [{x:1.35135, y:0.10463},{x:2.4182, y:0.9776},
        {x:5.761, y:9.85},{x:9.886, y:38.919},
        {x:13.549, y:100.758},{x:19.7368, y:307.981}, {x:24.7155, y:589.511}, {x:25, y:600}],
      fill: 2,
      pointStyle:'line',
      label: "Unstable zone"
    },
    { type: 'line',
      data: [{x:3.3784, y:0.1046},{x:4.516, y:1.007},{x:8.357, y:10.152}, {x:12.02, y:29.21},
             {x:16.216, y:81.56},{x:20.09, y:158.489},{x:24.822, y:303.366}, {x:25, y:320}],
      fill: true,
      pointStyle:'line',
      label: "Failure zone"
    },
    // { type: 'scatter',
    //   data: [],
    //   label:'stope surfaces',
    //   fill: true,
    //   pointRadius:3,
    {  type: 'scatter',
    
    pointStyle:[],
    pointBorderColor:[],
      data: [],
      label:'Stope Surfaces',
      fill: true,
      pointRadius:5,
    }
  ];

  public extendedChartData: ChartDataSets[] = [
    { type: 'line',
    data: [{x:0.8, y:0.14115},{x:0.8, y:1001},{x:100, y:1000},{x:100, y:923.739}],
    fill: 1,
    pointStyle:'line',
    label: "Stable zone"
    },
    { type: 'line',
      data: [{x:1, y:0.21186},{x:30, y:103.285},{x:100, y:923.739}],
      fill: 2,
      pointStyle:'line',
      label: "Failure & major failure zone"
    },
    { type: 'line',
      data: [{x:1, y:0.00092},{x:30, y:0.44981},{x:101, y:4.0964}],
      fill: true,
      pointStyle:'line',
      label: "Caving zone"
    },
    {
      //  type: 'scatter',
      // data: [],
      // label:'stope surfaces',
      // fill: true,
      // pointRadius:3,
      type: 'scatter',
    
    pointStyle:[],
    pointBorderColor:[],
      data: [],
      label:'Stope Surfaces',
      fill: true,
      pointRadius:5,
    }
  ];

  public ChartColors: Color[] = [
    { backgroundColor: 'rgba(34,101, 86, 0.5)', borderColor: 'none',borderWidth:0},
    { backgroundColor: 'rgba(0,0,0,0.5)', borderColor: 'none', borderWidth:0},
    { backgroundColor: 'rgba(255,0,0,0.45)', borderColor: 'none', borderWidth:0},
    { backgroundColor: '#000000', borderColor: 'none', borderWidth:0},
  ];

  ngAfterViewInit():void{
   
if(! isUndefined(this.sService.getCaution))
    {

    this.dialogConfig = new MatDialogConfig();
    this.dialogConfig.width ='45%';
    // this.dialogConfig.height='55%';

    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.dialogConfig.data = {
    };
    this.dialog.open(QvalueComponentStopSurface,this.dialogConfig )
  }
    // this.dialogConfig.close=true


  }
   async fnc(){
     this.getCalculations();
    ////console.log("fnc.....\n\n\n")

  }
  
  async crownReq(){
    return new Promise ((resolve,reject)=>{
      if(this.abcService.getAbcStopes().includes("Crown") ){
      console.log("\n\n\n Crown values : ",this.crownN_mod)
      this.sService.postReq(this.hrService.getcrown(),this.crownN_mod).subscribe(data1=>{
       console.log("the Big D",data1);
       const result=data1["results"][0]
       console.log("-----<>",result[0].toFixed(2));
        this.dataSetCrown(data1);
    
       //  setTimeout(() => {
       //   this.router.navigate(['/stability'], {relativeTo: this.route});
     
       //   // this.router.navigate(['/qvalue'], {relativeTo: this.route});
     
       // }, 1000);
         //  this.router.navigate(['/stability'], {relativeTo: this.route});
    
         if(result[0]>=result[1] && result[0]>= result[2])
         this.sService.setCrownOutcome("STABLE");
         else if(result[1]>=result[0] && result[1]>= result[2]){
           this.sService.setCrownOutcome("UNSTABLE");
  
         }
         else if(result[2]>=result[0] && result[2]>= result[1]){
           this.sService.setCrownOutcome("FAILURE");
  
         }
         else{
           this.sService.setCrownOutcome("CAVING");
  
         }
       
        
       (<HTMLInputElement>document.getElementById("crownstable")).innerText=  ((result[0]*100).toFixed(0) )+"%"as unknown as string;
       (<HTMLInputElement>document.getElementById("crownunstable")).innerText=((result[1]*100).toFixed(0) )+"%"as unknown as string;
       (<HTMLInputElement>document.getElementById("crownfailure")).innerText= ((result[2]*100).toFixed(0) )+"%"as unknown as string;
       // ////console.log((<HTMLInputElement>document.getElementById("crownstable")))
       resolve("done")

      }
     )
  }
else  resolve("crown not included")
})

   
  }
  async hwReq(){
return new Promise((resolve,reject)=>{
  if(this.abcService.getAbcStopes().includes("HW") ){
  this.sService.postReq(this.hrService.gethang(),this.hwN_mod).subscribe(data1=>{
    ////console.log("the Big D",data1["results"]);
    const result=data1["results"][0]
    ////console.log("-----<>",result[0].toFixed(2));


    this.dataSetHang(data1);

      if(result[0]>=result[1] && result[0]>= result[2])
      this.sService.setHWOutcome("STABLE");
      else if(result[1]>=result[0] && result[1]>= result[2]){
        this.sService.setHWOutcome("UNSTABLE");

      }
      else if(result[2]>=result[0] && result[2]>= result[1]){
        this.sService.setHWOutcome("FAILURE");

      }
      else{
        this.sService.setHWOutcome("CAVING");

      }
  
    (<HTMLInputElement>document.getElementById("hangstable")).innerText=  ((result[0]*100).toFixed(0) )+"%"as unknown as string;
    (<HTMLInputElement>document.getElementById("hangunstable")).innerText=((result[1]*100).toFixed(0) )+"%"as unknown as string;
    (<HTMLInputElement>document.getElementById("hangfailure")).innerText= ((result[2]*100).toFixed(0) )+"%"as unknown as string;
    resolve("done")

    // ////console.log((<HTMLInputElement>document.getElementById("crownstable")))
  }
  )
}
else
resolve("hw not included")

})
  }
  
  async fwReq(){
    return new Promise ((resolve,reject)=>{
      if(this.abcService.getAbcStopes().includes("FW") ){
        //this.fwN_mod
      this.sService.postReq(this.hrService.getfoot(),this.fwN_mod).subscribe(data1=>{
        ////console.log("the Big D",data1["results"]);
        const result=data1["results"][0]
        ////console.log("-----<>",result[0].toFixed(2));
  
        this.dataSetFoot(data1);
  
          if(result[0]>=result[1] && result[0]>= result[2])
          this.sService.setFWOutcome("STABLE");
          else if(result[1]>=result[0] && result[1]>= result[2]){
            this.sService.setFWOutcome("UNSTABLE");
  
          }
          else if(result[2]>=result[0] && result[2]>= result[1]){
            this.sService.setFWOutcome("FAILURE");
  
          }
          else{
            this.sService.setFWOutcome("CAVING");
  
          }
        
        (<HTMLInputElement>document.getElementById("footstable")).innerText=  ((result[0]*100).toFixed(0) )+"%"as unknown as string;
        (<HTMLInputElement>document.getElementById("footunstable")).innerText=((result[1]*100).toFixed(0) )+"%"as unknown as string;
        (<HTMLInputElement>document.getElementById("footfailure")).innerText= ((result[2]*100).toFixed(0) )+"%"as unknown as string;
        resolve("done")

        // ////console.log((<HTMLInputElement>document.getElementById("crownstable")))
      }
      )
    }
    else
    resolve("fw not included")
})
  }
  
  async sw2Req(){
    return new Promise ((resolve,reject)=>{
      if(this.abcService.getAbcStopes().includes("SW2") ){
        //this.side2N_mod
      this.sService.postReq(this.hrService.getside(),this.side2N_mod).subscribe(data1=>{
        ////console.log("the Big D",data1["results"]);
        const result=data1["results"][0]
        ////console.log("-----<>",result[0].toFixed(2));
  
  
        this.dataSetSide2(data1);
        // if(this.qservice.get()=="SW2"){
          if(result[0]>=result[1] && result[0]>= result[2])
          this.sService.setSW2Outcome("STABLE");
          else if(result[1]>=result[0] && result[1]>= result[2]){
            this.sService.setSW2Outcome("UNSTABLE");
  
          }
          else if(result[2]>=result[0] && result[2]>= result[1]){
            this.sService.setSW2Outcome("FAILURE");
  
          }
          else{
            this.sService.setSW2Outcome("CAVING");
  
          }
        // }
        (<HTMLInputElement>document.getElementById("sw2stable")).innerText=  ((result[0]*100).toFixed(0) )+"%"as unknown as string;
        (<HTMLInputElement>document.getElementById("sw2unstable")).innerText=((result[1]*100).toFixed(0) )+"%"as unknown as string;
        (<HTMLInputElement>document.getElementById("sw2failure")).innerText= ((result[2]*100).toFixed(0) )+"%"as unknown as string;
        resolve("done")

        // ////console.log((<HTMLInputElement>document.getElementById("crownstable")))
      }
      )
    }
  else
  resolve("sw2 not included")
})
  }
  
  async sw1Req(){
    return new Promise ((resolve,reject)=>{
      if(this.abcService.getAbcStopes().includes("SW1") ){
      this.sService.postReq(this.hrService.getside(),this.side1N_mod).subscribe(data1=>{
        ////console.log("the Big D",data1["results"]);
        const result=data1["results"][0]
        ////console.log("-----<>",result[0].toFixed(2));
  
  
        this.dataSetSide1(data1);
          if(result[0]>=result[1] && result[0]>= result[2])
          this.sService.setSW1Outcome("STABLE");
          else if(result[1]>=result[0] && result[1]>= result[2]){
            this.sService.setSW1Outcome("UNSTABLE");
  
          }
          else if(result[2]>=result[0] && result[2]>= result[1]){
            this.sService.setSW1Outcome("FAILURE");
  
          }
          else{
            this.sService.setSW1Outcome("CAVING");
  
          }
        
        (<HTMLInputElement>document.getElementById("sw1stable")).innerText=  ((result[0]*100).toFixed(0) )+"%"as unknown as string;
        (<HTMLInputElement>document.getElementById("sw1unstable")).innerText=((result[1]*100).toFixed(0) )+"%"as unknown as string;
        (<HTMLInputElement>document.getElementById("sw1failure")).innerText= ((result[2]*100).toFixed(0) )+"%"as unknown as string;
        resolve("done")

        // ////console.log((<HTMLInputElement>document.getElementById("crownstable")))
      }
      )
    }
  else
resolve("sw1 not included")})
  }
   checkfunc(){
    this.service.currentHrFoot.subscribe(hrFoot => this.hrFoot = hrFoot);
    this.service.currentHrHang.subscribe(hrHang => this.hrHang = hrHang);
    this.service.currentHrSide.subscribe(hrSide=> this.hrSide = hrSide);

    this.service.currentCrownN.subscribe(crownN=> this.crownN = crownN);
    this.service.currentHwN.subscribe(hwN=> this.hwN = hwN);
    this.service.currentFwN.subscribe(fwN=> this.fwN = fwN);
    this.service.currentSide2N.subscribe(side2N=> this.side2N = side2N);
    this.service.currentSide1N.subscribe(side1N=> this.side1N = side1N);
    
    this.service.currentHrCrown.subscribe(hrCrown => this.hrCrown = hrCrown);
    this.service.currentCrownN_mod.subscribe(crownN_mod=> this.crownN_mod = crownN_mod);
    this.service.currentHwN_mod.subscribe(hwN_mod=> this.hwN_mod = hwN_mod);
    this.service.currentFwN_mod.subscribe(fwN_mod=> this.fwN_mod = fwN_mod);
    this.service.currentSide1N_mod.subscribe(side1N_mod=> this.side1N_mod = side1N_mod);
    this.service.currentSide2N_mod.subscribe(side2N_mod=> this.side2N_mod = side2N_mod);

        this.crownReq().then((val)=>{
          this.hwReq().then((val)=>{
            this.fwReq().then((val)=>{
              this.sw1Req().then((val)=>{
                this.sw2Req().then((val)=>{
                  let count=0;
                  let CheckStable=0;
                  // setTimeout(() => {
                  //   console.log("ssss")
                  //   this.dialog.closeAll()
                  // }, 2000);
                  if(this.abcService.getAbcStopes().length>5){
                    //Only then work here
                    if(this.abcService.getAbcStopes().includes("Crown")){
              
                
                       if(this.sService.getCrownOutcome()=="STABLE"){
                        ////console.log("\n\n\n\n\n\n\n\n\n->>>>>>>>>",this.sService.getCrownOutcome());
                        count++}
                       else if(isUndefined(this.sService.getCrownOutcome())){ CheckStable=2}
                       else
                       {CheckStable=3}
                
                    }
                    if(this.abcService.getAbcStopes().includes("HW")){
                      if(this.sService.getHWOutcome()=="STABLE"){
                        ////console.log("\n\n\n\n\n\n\n\n\n->>>>>>>>>",this.sService.getHWOutcome());
                        count++}
                       else if(isUndefined(this.sService.getHWOutcome())){ CheckStable=2}
                       else
                      {CheckStable=3}
                
                   }
                   
                   if(this.abcService.getAbcStopes().includes("FW") ){
                    if(this.sService.getFWOutcome()=="STABLE"){
                      ////console.log("\n\n\n\n\n\n\n\n\n->>>>>>>>>",this.sService.getFWOutcome());
                      count++}
                     else if(isUndefined(this.sService.getFWOutcome())){ CheckStable=2}
                       else
                    {CheckStable=3}
                
                 }
                 
                 if(this.abcService.getAbcStopes().includes("SW1") ){
                  if(this.sService.getSW1Outcome()=="STABLE"){
                    ////console.log("\n\n\n\n\n\n\n\n\n->>>>>>>>>",this.sService.getSW1Outcome());
                    count++}
                   else if(isUndefined(this.sService.getSW1Outcome())){ CheckStable=2}
                       else
                  {CheckStable=3}
                
                }
                
                if(this.abcService.getAbcStopes().includes("SW2") ){
                  if(this.sService.getSW2Outcome()=="STABLE"){
                    ////console.log("\n\n\n\n\n\n\n\n\n->>>>>>>>>",this.sService.getSW2Outcome());
              
                    count++}
                   else if(isUndefined(this.sService.getSW2Outcome())){ CheckStable=2}
                       else
                  {CheckStable=3}
                
                }
                
              
              
                   if(count==5){
                     ////console.log("\n\n\n\n\nNot Caution")
                    this.sService.setCaution("Note: The stope you are checking is stable because all stope surfaces are stable. ");
                    // (<HTMLInputElement>document.getElementById("cautionInfo")).innerText= "Note: The stope you are checking is stable because all stope surfaces are stable. "
                  this.cautionValue="Note: The stope you are checking is stable because all stope surfaces are stable. "
                  }
                  else{
                    ////console.log("\n\n\n\n\n Caution")
              
                    this.sService.setCaution("CAUTION: The stope you are checking is not stable. For stope to be stable please ensure that all stope surfaces are stable. ");
                    this.cautionValue= "CAUTION: The stope you are checking is not stable. For stope to be stable please ensure that all stope surfaces are stable. ";
                 
                  }
                  
              
              
                  }
                  else{
                    ////console.log("\n\n\nCaution")
              
                    this.sService.setCaution("CAUTION: The stope you are checking is not stable. For stope to be stable please ensure that all stope surfaces are stable. ");
                    (<HTMLInputElement>document.getElementById("cautionInfo")).innerText= "CAUTION: The stope you are checking is not stable. For stope to be stable please ensure that all stope surfaces are stable. ";
                 
                  }
                  this.dialog.closeAll()
                })
              })
            })
          })

        })
      
        
     
   

    
  }
   async getCalculations(){
    ////console.log("After here",this.qservice.getStope())
    if(this.abcService.getAbcStopes().includes("Crown") ){
      (<HTMLInputElement>document.getElementById("crownstable")).innerText="...";
      (<HTMLInputElement>document.getElementById("crownunstable")).innerText="...";
      (<HTMLInputElement>document.getElementById("crownfailure")).innerText= "...";

    }
    if(this.abcService.getAbcStopes().includes("HW") ){

    (<HTMLInputElement>document.getElementById("hangstable")).innerText=  "...";
    (<HTMLInputElement>document.getElementById("hangunstable")).innerText="...";
    (<HTMLInputElement>document.getElementById("hangfailure")).innerText= "...";
    }
    if(this.abcService.getAbcStopes().includes("FW") ){

    (<HTMLInputElement>document.getElementById("footstable")).innerText=  "...";
    (<HTMLInputElement>document.getElementById("footunstable")).innerText="...";
    (<HTMLInputElement>document.getElementById("footfailure")).innerText= "...";
    }
    if(this.abcService.getAbcStopes().includes("SW1") ){


    (<HTMLInputElement>document.getElementById("sw1stable")).innerText=  "...";
    (<HTMLInputElement>document.getElementById("sw1unstable")).innerText="...";
    (<HTMLInputElement>document.getElementById("sw1failure")).innerText= "...";
    }
    if(this.abcService.getAbcStopes().includes("SW2") ){

    (<HTMLInputElement>document.getElementById("sw2stable")).innerText=  "...";
    (<HTMLInputElement>document.getElementById("sw2unstable")).innerText="...";
    (<HTMLInputElement>document.getElementById("sw2failure")).innerText= "...";
    }

    // this.service.currentHrCrown.subscribe(hrCrown => this.hrCrown = hrCrown);
    // this.service.currentCrownN_mod.subscribe(crownN_mod=> this.crownN_mod = crownN_mod);
    // this.service.currentHwN_mod.subscribe(hwN_mod=> this.hwN_mod = hwN_mod);
    // this.service.currentFwN_mod.subscribe(fwN_mod=> this.fwN_mod = fwN_mod);
    // this.service.currentSide1N_mod.subscribe(side1N_mod=> this.side1N_mod = side1N_mod);
    // this.service.currentSide2N_mod.subscribe(side2N_mod=> this.side2N_mod = side2N_mod);
    
    console.log("CHeck Function before")
     this.checkfunc()
     console.log("CHeck Function before")

  
  }
  
  ngOnInit(): void {
    ////console.log('here')
    // ////console.log("1->",(document.getElementById("xeno")));
    // ////console.log("2->",(<HTMLDivElement>document.getElementById("xeno")));

    // ////console.log("3->",(document.getElementById("xoxo")));
    // ////console.log("4->",(<HTMLDivElement>document.getElementById("mat-tab-label-1-0")));

    ////console.log("After here->>>>>>>>     ",this.qservice.getStope())
  
    // this.sService.postReq(this.hrService.getcrown(),this.abcService.getN())

   this.fnc().then(()=>{
     ////console.log("then.....\n\n\n")
    
  
   });

    // this.router.navigate(['/stress'], {relativeTo: this.route});
    // setTimeout(()=>{
    //   this.router.navigate(['/stability'], {relativeTo: this.route});

    // },500)
    // ////console.log("STABILITY DONE?")
    // let hrCrown=(this.hrService.getcrown() as unknown as number  ) .toFixed(2) as unknown as number;
    // let hrHw=(this.hrService.gethang() as unknown as number  ) .toFixed(2) as unknown as number;
    // let hrFw=(this.hrService.getfoot() as unknown as number  ) .toFixed(2) as unknown as number;
    // let hrSw1=(this.hrService.getside() as unknown as number  ) .toFixed(2) as unknown as number;
    // let hrSw2=(this.hrService.getside() as unknown as number  ) .toFixed(2) as unknown as number;

    const staticHrValues=['3','5','7','9','11','13','15','17','19','21','23','25'];
  //  ////console.log("\n\nCrown:",hrCrown)
     this.crownChartLabels=staticHrValues;
     ////console.log("\n\nHang:")

 this.hwChartLabels    =staticHrValues;
 ////console.log("\n\nFoot:")

 this.fwChartLabels    =staticHrValues;
 ////console.log("\n\nSW1:")

 this.side1ChartLabels =staticHrValues;
 ////console.log("\n\nSw2:")

 this.side2ChartLabels =staticHrValues;

    //for caution info
    let CheckStable=0;
    let count=0;
    ////console.log("\n\n\n\n\n\n\n\n\nOUTCOME",this.sService.getCrownOutcome());
    ////console.log("\n\n\n\n\n\n\n\n\nOUTCOME",this.sService.getHWOutcome());
    ////console.log("\n\n\n\n\n\n\n\n\nOUTCOME",this.sService.getFWOutcome());
    ////console.log("\n\n\n\n\n\n\n\n\nOUTCOME",this.sService.getSW1Outcome());
    ////console.log("\n\n\n\n\n\n\n\n\nOUTCOME",this.sService.getSW2Outcome());

    

    ////console.log('a:',this.abcService.getA(),"- b: ",this.abcService.getB(),"- c: ",this.abcService.getC(),"- n : ",this.abcService.getN())

    this.prediction = this.predictionService.getPrediction();
    this.subscription = this.predictionService.predictChanged
      .subscribe(
        (predict: Predict) => {
          ////console.log('zawel');
          ////console.log(predict)
          this.prediction = predict;
        }
      );
      this.service.currentHrFoot.subscribe(hrFoot => this.hrFoot = hrFoot);
      this.service.currentHrHang.subscribe(hrHang => this.hrHang = hrHang);
      this.service.currentHrSide.subscribe(hrSide=> this.hrSide = hrSide);
  
      this.service.currentCrownN.subscribe(crownN=> this.crownN = crownN);
      this.service.currentHwN.subscribe(hwN=> this.hwN = hwN);
      this.service.currentFwN.subscribe(fwN=> this.fwN = fwN);
      this.service.currentSide2N.subscribe(side2N=> this.side2N = side2N);
      this.service.currentSide1N.subscribe(side1N=> this.side1N = side1N);
      
      this.service.currentHrCrown.subscribe(hrCrown => this.hrCrown = hrCrown);
      this.service.currentCrownN_mod.subscribe(crownN_mod=> this.crownN_mod = crownN_mod);
      this.service.currentHwN_mod.subscribe(hwN_mod=> this.hwN_mod = hwN_mod);
      this.service.currentFwN_mod.subscribe(fwN_mod=> this.fwN_mod = fwN_mod);
      this.service.currentSide1N_mod.subscribe(side1N_mod=> this.side1N_mod = side1N_mod);
      this.service.currentSide2N_mod.subscribe(side2N_mod=> this.side2N_mod = side2N_mod);
   
    const data0 = [{x:this.hrCrown, y: this.crownN_mod}, {x:this.hrHang, y:this.hwN_mod},{x:this.hrFoot, y:this.fwN_mod} ,{x:this.hrSide, y:this.side1N_mod},
      {x:this.hrSide, y:this.side2N_mod}];
    
    const data1 = [{x:this.hrCrown, y: this.crownN_mod}, {x:this.hrHang, y:this.hwN_mod},{x:this.hrFoot, y:this.fwN_mod} ,{x:this.hrSide, y:this.side1N_mod},
      {x:this.hrSide, y:this.side2N_mod}];
    
    const data2 = [{x:this.hrCrown, y: this.crownN}, {x:this.hrHang, y:this.hwN},{x:this.hrFoot, y:this.fwN},{x:this.hrSide, y:this.side1N},
      {x:this.hrSide, y:this.side2N}, ];

      // pointStyle:['circle','triangle','rectRot','circle','circle'],
      // pointBorderColor:['red','green','blue','black','#8a00d4'],
      // let styleArray=[]
      let colorArray=[]
if(this.abcService.getAbcStopes().includes("Crown")){
// styleArray.push('circle')
colorArray.push('red')
}
if(this.abcService.getAbcStopes().includes("HW")){
  // styleArray.push('triangle')
  colorArray.push('green')

}

if(this.abcService.getAbcStopes().includes("FW")){
  // styleArray.push('rectRot')
  colorArray.push('blue')

}

if(this.abcService.getAbcStopes().includes("SW1")){
  // styleArray.push('circle')
  colorArray.push('black')

}

if(this.abcService.getAbcStopes().includes("SW2")){
  // styleArray.push('circle')
  colorArray.push('#8a00d4')

}

// this.refinedChartData[3].pointStyle=styleArray;
// this.modifiedChartData[3].pointStyle=styleArray;
// this.extendedChartData[3].pointStyle=styleArray;
// this.refinedChartData[3].pointBorderColor="black";
// this.modifiedChartData[3].pointBorderColor="black";
// this.extendedChartData[3].pointBorderColor="black";
this.refinedChartData[3].pointBackgroundColor=colorArray;
this.modifiedChartData[3].pointBackgroundColor=colorArray;
this.extendedChartData[3].pointBackgroundColor=colorArray;
////console.log('\n\nnew style array->',this.refinedChartData[3].pointStyle )
////console.log('\n\nnew color array->',this.refinedChartData[3].pointBackgroundColor  )

      ////console.log("\n\n\nthis->",data0)
      const dd0=[]
      ////console.log("\n\n\nthis->",data0)
      for(let i=0 ; i<data0.length;i++){
        if(isNaN(data0[i].y)){
          
        }
        else{
        
          ////console.log("pushing...", data0[i])
          dd0.push(data0[i])
        }
      }
      const dd1=[]
      ////console.log("\n\n\nthis->",data1)
      for(let i=0 ; i<data1.length;i++){
        if(isNaN(data1[i].y)){
          
        }
        else{
          ////console.log("pushing...", data1[i])
          dd1.push(data1[i])
        }
      }
      const dd2=[]
      ////console.log("\n\n\nthis->",data2)
      for(let i=0 ; i<data2.length;i++){
        if(isNaN(data2[i].y)){
          
        }
        else{
          ////console.log("pushing...", data2[i])
          dd2.push(data2[i])
        }
      }
     this.refinedChartData[3].data = dd0;
     console.log("\n\n\n\n graph vales d0 : ",data0,dd1)
     console.log("\n\n\n\n graph vales d1: ",data1,dd1)
     console.log("\n\n\n\n graph vales d2: ",data2,dd2)


    this.modifiedChartData[3].data = dd1;
    this.extendedChartData[3].data = dd2;
  }
}


@Component({
  selector: 'qvalue-component-stope-surface',
  templateUrl: './qvalue.component-stope-surface.html',
  styleUrls: ['./qvalue.component-stope-surface.scss']
})
export class QvalueComponentStopSurface {

  constructor(
    public dialogRef: MatDialogRef<QvalueComponentStopSurface>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
    onClick(e){
      
  
        this.dialogRef.close(this.data);

    }
    openModal(){
      console.log("shsh")
    }
}
