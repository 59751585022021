<div class="col-40">    
  <svg xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="viewBox">      
    <text x="-20" y="-160" fill="black" font-size = "20">Induced stresses</text>           
    <text x="-10" y="-130" fill="black">Crown &sigma;<tspan baseline-shift ="sub">ind</tspan> = {{crownIndStress}} MPa</text>
    <text x="-150" y="160" fill="black">Sidewall &sigma;<tspan baseline-shift ="sub">ind</tspan> = {{sideIndStress}} MPa</text>           
    <text x="130" y="180" fill="black">HW &sigma;<tspan baseline-shift ="sub">ind</tspan> = {{hwIndStress}} MPa</text>
    <defs> <!-- arrowhead marker definition -->                          
      <marker id="arrow" [attr.viewBox_marker]="viewBox_marker" [attr.refX]="refX" [attr.refY]="refY" 
        [attr.markerWidth]="10" [attr.markerHeight]="10" orient="auto-start-reverse">                             
        <path d="M 0 0 L 10 5 L 0 10 z"/>                        
      </marker>                         
    </defs>                     
    <!-- Coordinate axes with a arrowhead in both direction -->             
    <polyline [attr.points]=[[0,0],[0,240],[120,200],[120,-40],[0,0],[-40,-60],[80,-100],[120,-40]] style="fill:none;stroke:black;"/>
    <polyline [attr.points]=[[-40,-60],[-40,180],[0,240]] style="fill:none;stroke:black;"/>             
    <polyline [attr.points]=[[80,-100],[80,140],[-40,180]] style="fill:none;stroke:black; stroke-dasharray:10 5;"/>
    <line x1="80" y1="140" x2="120" y2="200" style="stroke:black;stroke-width:1;stroke-dasharray: 10 5;"/>
    <polyline [attr.points]=[[20,-80],[60,-20],[60,220]] style="fill:none;stroke:black;"/>           
    <polyline [attr.points]=[[-40,60],[0,120],[120,80]] style="fill:none;stroke:black;"/>            
    <line x1="140" y1="160" x2="70" y2="110" style="stroke:black;stroke-width:1.5" marker-end="url(#arrow)"/>
    <line x1="-80" y1="140" x2="-30" y2="90" style="stroke:black;stroke-width:1.5" marker-end="url(#arrow)"/>
    <line x1="40" y1="-120" x2="40" y2="-60" style="stroke:black;stroke-width:1.5" marker-end="url(#arrow)"/>
    
    <text x="-280" y="-120" fill="black" font-size = "20">In-situ stresses</text>           
    <text x="-270" y="-75" fill="black">&sigma;<tspan baseline-shift ="sub">v</tspan>= {{Verticalstress}} MPa</text>
    <text x="-195" y="90" fill="black">&sigma;<tspan baseline-shift ="sub">h2</tspan>= {{Horizontalstress}} MPa</text> 
    <text x="-175" y="0" fill="black">&sigma;<tspan baseline-shift ="sub">h1</tspan>= {{Horizontalstress}} MPa</text>   
    <!-- Coordinate axes with a arrowhead in both direction -->           
    <polyline [attr.points]=[[-260,-60],[-260,20],[-180,0]] style="stroke:black;stroke-width:1; fill:none"            
        marker-start="url(#arrow)" marker-end="url(#arrow)"/>          
    <line x1="-260" y1="20" x2="-200" y2="80" style="stroke:black;stroke-width:1" marker-end="url(#arrow)"/>
  </svg>

  <div class="next"> 
    <button class="mat-raised-button mat-primary" routerLink="/factors">Next &raquo;</button>
  </div>
</div>
  
  
  