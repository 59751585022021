import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    constructor(private router: Router) {
    }
    title = 'Open-Stope';


    ngOnInit() {
        this.setUpAnalytics();
    }
  
setUpAnalytics() {
  this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
          gtag('config', 'G-P51JEYXYF1',
              {
                  page_path: event.urlAfterRedirects
              }
          );
      });
}
}
