
  <div id="wrapper">
    <img src="assets/img/ThinkRox.jpg" alt="ThinkRox Inc." style="width:12%; height:8%; margin-left: 15%;">
    <img src="assets/img/NUlogo.jpg" alt="Nazarbayev University" style="width:18%; height:16%; margin-left:6%">
</div>

<div class="bg" style="padding-top: 0;">
  <img src="assets/img/Stope.png" alt="Stope" style="width:100%; height:100%;">
</div>

<div class="welcome">
  <h1>Welcome to an automated integrated open stope design tool.<br>StopeSoft tool is developed through a collaboration between <br>
    ThinkRox Inc. and Nazarbayev University through the <br>Faculty Competitive Development Grant No. 240919FD3920.</h1>
  <button class="nav-item nav-link-edit" [routerLink]="['hr']">Get Started</button>
</div>
