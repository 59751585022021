<app-tool-nav></app-tool-nav>
<div class="bg">
  <div class="col-40">
    <h4>Hydraulic Radius Calculation</h4>
    <p> Please select  stope geometry and enter dimensions below.</p>
    <hr>
      <div class="symmetry">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['./symmetric']" [routerLinkActiveOptions]="{exact: false}">Stope is symmetric</a>
        <a style ="margin-left:6%" class="nav-link" [routerLinkActive]="['active']" [routerLink]="['./asymmetric']" [routerLinkActiveOptions]="{exact: false}">asymmetric</a> 
        <i class="fas fa-info-circle" aria-hidden="true" matTooltip="Asymmetric stope is assumed to be trapezoidal prism." 
        matTooltipPosition="right" matTooltipClass="toolTip"></i>
      </div>
    <router-outlet></router-outlet>
  </div>
</div>