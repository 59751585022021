<nav>
  <ul>
    <div id="logo">
      <li class="nav-item logo-nav"><a href="/"><img src="assets/img/logo.png" alt="logo" style="width:100%; top:0%; left: 0%; position: absolute; z-index:1; padding:10%;"></a>
      </li>
   </div>
     <li class="nav-item" style="margin-top:40%"><a  href="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><i class="fa fa-home"></i></a></li>
     <li class="nav-item"><a routerLink="/about" routerLinkActive="active"><i class="fa fa-search"></i></a></li>
     <li class="nav-item"><a routerLink="/contact" routerLinkActive="active"><i class="fa fa-envelope"></i></a></li>
     <li class="share"><button (click)="shareData()"><i class="fa fa-globe"></i></button></li>
  </ul>
</nav>
