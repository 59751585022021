import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ForAllService } from '../services/for-all.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-q-res',
  templateUrl: './q-res.component.html',
  styleUrls: ['./q-res.component.scss']
})
export class QResComponent implements OnInit {
  public rqd_crown: number;
  public rqd_hw: number;
  public rqd_fw: number;
  public rqd_sw1: number;
  public rqd_sw2: number;

  public jointSet: number;
  public jointRoughness: number;
  public jointAlteration: number;

  public qQuality_crown: string;
  public qQuality_fw: string;
  public qQuality_hw: string;
  public qQuality_sw1: string;
  public qQuality_sw2: string;

  public rqdQuality_crown: string;
  public rqdQuality_hw: string;
  public rqdQuality_fw: string;
  public rqdQuality_sw1: string;
  public rqdQuality_sw2: string;

  public Jn: string;
  public Jr: string;
  public Ja: string;

  public qRes_crown: number;
  public qRes_hw: number;
  public qRes_fw: number;
  public qRes_sw1: number;
  public qRes_sw2: number;
 

public barChartOptionsQ: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
}
  public barChartOptionsRQD: ChartOptions = {

    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position:'right',
      display: true,
      labels: {
          fontColor:'black',
          generateLabels: function(chart) {
            var data = chart.data;
           const ded= ['#9DE3D5','#76D7C4','#19B194','#148F77']
            const barLegends = ['VERY POOR', 'POOR', 'FAIR', 'GOOD'];

            console.log("de->",data)
            if (barLegends.length && data.datasets.length) {
                return barLegends.map(function(label, i) {
                    var meta = chart.getDatasetMeta(0);
                    var ds = data.datasets[0];
                    var arc = meta.data[i];
                    var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
                    var arcOpts = chart.options.elements.arc;
                    // var fill = backgroundColor ? backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
                    // var stroke = borderColor ? borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
                    // var bw = borderWidth ? borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

      // We get the value of the current label
      var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                    return {
                        // Instead of `text: label,`
                        // We add the value to the string
                        text: label + " " ,
                         fillStyle: ded[i],
                        // strokeStyle: stroke,
                        // lineWidth: bw,
                        hidden: isNaN(<any>ds.data[i]) || meta.data[i].hidden,
                        index: i
                    };
                });
            } else {
                return [];
            }     
         }
  }}
  };
  

  public barChartLabels: Label[] = ['Crown', 'Hangingwall', 'Footwall', 'Sidewall 1', 'Sidewall 2'];

  public   barChartLabelsQModified  : Label[] = ['Crown', 'Hangingwall', 'Footwall', 'Sidewall 1', 'Sidewall 2'];

  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend =true;
  public barChartLegendQ =false;

  public barChartPlugins = [];
  
  public ChartColors: Color[] = [
    {backgroundColor: ['','','','']},
 

  ];
  public ChartColorsRQD: Color[] = [
    {backgroundColor: '#148f77'},

  ]

  constructor(private service: ForAllService) { }

  public barChartDataQ: ChartDataSets[] = [
    { data: [] },
   
  ];

  public barChartDataRQD: ChartDataSets[] = [
    { data : [], label: "RQD"},
    // { data: [], label: 'RQD2 (Rock Quality Designation)' },
    // { data: [], label: 'RQD3 (Rock Quality Designation)' },
    // { data: [], label: 'RQD4 (Rock Quality Designation)' }

  ];

  ngOnInit(): void {
    const SetColors= ['#9DE3D5','#76D7C4','#19B194','#148F77'];

    this.service.currentRqd_crown.subscribe(rqd_crown => this.rqd_crown = rqd_crown);
    this.service.currentRqd_hw.subscribe(rqd_hw => this.rqd_hw = rqd_hw);
    this.service.currentRqd_fw.subscribe(rqd_fw => this.rqd_fw = rqd_fw);
    this.service.currentRqd_sw1.subscribe(rqd_sw1 => this.rqd_sw1 = rqd_sw1);
    this.service.currentRqd_sw2.subscribe(rqd_sw2 => this.rqd_sw2 = rqd_sw2);

    this.service.currentQRes_crown.subscribe(qRes_crown => this.qRes_crown = qRes_crown);
    this.service.currentQRes_hw.subscribe(qRes_hw => this.qRes_hw = qRes_hw);
    this.service.currentQRes_fw.subscribe(qRes_fw => this.qRes_fw = qRes_fw);
    this.service.currentQRes_sw1.subscribe(qRes_sw1 => this.qRes_sw1 = qRes_sw1);
    this.service.currentQRes_sw2.subscribe(qRes_sw2 => this.qRes_sw2 = qRes_sw2);

    this.service.currentQQuality_crown.subscribe(qQuality_crown => this.qQuality_crown = qQuality_crown);
    this.service.currentQQuality_hw.subscribe(qQuality_hw => this.qQuality_hw = qQuality_hw);
    this.service.currentQQuality_fw.subscribe(qQuality_fw => this.qQuality_fw = qQuality_fw);
    this.service.currentQQuality_sw1.subscribe(qQuality_sw1 => this.qQuality_sw1 = qQuality_sw1);
    this.service.currentQQuality_sw2.subscribe(qQuality_sw2 => this.qQuality_sw2 = qQuality_sw2);

    this.service.currentRqdQuality_crown.subscribe(RqdQuality_crown => this.rqdQuality_crown = RqdQuality_crown);
    this.service.currentRqdQuality_hw.subscribe(RqdQuality_hw => this.rqdQuality_hw = RqdQuality_hw);
    this.service.currentRqdQuality_fw.subscribe(RqdQuality_fw => this.rqdQuality_fw = RqdQuality_fw);
    this.service.currentRqdQuality_sw1.subscribe(RqdQuality_sw1 => this.rqdQuality_sw1 = RqdQuality_sw1);
    this.service.currentRqdQuality_sw2.subscribe(RqdQuality_sw2 => this.rqdQuality_sw2 = RqdQuality_sw2);

    this.service.currentJn.subscribe(Jn => this.Jn = Jn);
    this.service.currentJr.subscribe(Jr => this.Jr = Jr);
    this.service.currentJa.subscribe(Ja => this.Ja = Ja);

    const data0 = [this.qRes_crown,this.qRes_hw,this.qRes_fw, this.qRes_sw1,this.qRes_sw2];
    this.barChartDataQ[0].data = data0;

    const data1 = [this.rqd_crown,this.rqd_hw,this.rqd_fw, this.rqd_sw1,this.rqd_sw2];
    console.log("rqd:  ",data1);

//     if(this.rqd_crown>0){
// if()
//     }
//     if(this.rqd_hw>0){
      
//     }
//     if(this.rqd_fw>0){
      
//     }
//     if(this.rqd_sw1>0){
      
//     }
//     if(this.rqd_sw2>0){
      
//     }
    this.barChartDataRQD[0].data = data1;
 

    // const data2 = [this.rqd_crown,this.rqd_hw,this.rqd_fw, this.rqd_sw1,this.rqd_sw2];
    // this.barChartDataRQD[1].data = data2;
    // const data3 = [this.rqd_crown,this.rqd_hw,this.rqd_fw, this.rqd_sw1,this.rqd_sw2];
    // this.barChartDataRQD[2].data = data3;
    // const data4 = [this.rqd_crown,this.rqd_hw,this.rqd_fw, this.rqd_sw1,this.rqd_sw2];
    // this.barChartDataRQD[3].data = data4;
    console.log("rqd Crown: ",this.rqd_crown)
    console.log("--0-00-0->,",      this.ChartColors   )
    if(this.rqd_crown>0 && this.rqd_crown<=25){
      console.log("--0-before if(1)  poped00-0->,",      this.ChartColors  );
      (<any>this.ChartColors[0].backgroundColor[0])=SetColors[0];
      
      // if(this.rqd_crown>0){

      // this.barChartLabels[0]='Crown\nvery Poor'
      // }
      // else 
      // {
      //   this.barChartLabels[0]='Crown'
      // }
      // const tt=[...this.ChartColors[0].backgroundColor];
      // tt[0]=SetColors[0]
      // this.ChartColors[0].backgroundColor =tt.join();
    }
    else if(this.rqd_crown<=50){
      console.log("--0-before if2 poped00-0->,",      this.ChartColors  );
      (<any>this.ChartColors[0].backgroundColor[0])=SetColors[1];
      // if(this.rqd_crown>0){

      //   this.barChartLabels[0]='Crown\n Poor'
      //   }
      //   else 
      //   {
      //     this.barChartLabels[0]='Crown'
      //   }

      // const tt=[...this.ChartColors[0].backgroundColor];
      // tt[0]=SetColors[1]
      // this.ChartColors[0].backgroundColor =tt.join();

    }
    else if(this.rqd_crown<=75){
      console.log("--0-before if 3 poped00-0->,",      this.ChartColors  );
      (<any>this.ChartColors[0].backgroundColor[0])=SetColors[2];
      // this.barChartLabels[0]='Crown\nFair'
      // if(this.rqd_crown>0){

      //   this.barChartLabels[0]='Crown\nFair'
      //   }
      //   else 
      //   {
      //     this.barChartLabels[0]='Crown'
      //   }

      // const tt=[...this.ChartColors[0].backgroundColor];
      // tt[0]=SetColors[2]
      // this.ChartColors[0].backgroundColor =tt.join();

    }
    else if(this.rqd_crown<=100){
      console.log("--0-before if 4 poped00-0->,",      this.ChartColors  );
      (<any>this.ChartColors[0].backgroundColor[0])=SetColors[3];
      // if(this.rqd_crown>0){

      //   this.barChartLabels[0]='Crown\nGood'
      //   }
      //   else 
      //   {
      //     this.barChartLabels[0]='Crown'
      //   }
      

      // const tt=[...this.ChartColors[0].backgroundColor];
      // tt[0]=SetColors[3]
      // this.ChartColors[0].backgroundColor =tt.join();

    }
    else{

    }



    console.log("rqd HW: ",this.rqd_hw)
    console.log("--0-00-0->,",      this.ChartColors   )
    if(this.rqd_hw>0 && this.rqd_hw<=25){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[0]   =SetColors[0]

      (<any>this.ChartColors[0].backgroundColor[1])=SetColors[0];
      // if(this.rqd_hw>0){

      //   this.barChartLabels[1]='Hangingwall\nvery Poor'
      // }
      //   else 
      //   {
      //     this.barChartLabels[1]='Hangingwall'
      //   }

    }
    else if(this.rqd_hw<=50){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[1]   =SetColors[1]
      (<any>this.ChartColors[0].backgroundColor[1])=SetColors[1];
      // this.barChartLabels[1]='Hangingwall\n Poor'
      // if(this.rqd_hw>0){

      //   this.barChartLabels[1]='Hangingwall\n Poor'
      // }
      //   else 
      //   {
      //     this.barChartLabels[1]='Hangingwall'
      //   }


    }
    else if(this.rqd_hw<=75){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[2]   =SetColors[2]

            (<any>this.ChartColors[0].backgroundColor[1])=SetColors[2];
            // this.barChartLabels[1]='Hangingwall\nfair'
            // if(this.rqd_hw>0){

            //   this.barChartLabels[1]='Hangingwall\nFair'
            // }
            //   else 
            //   {
            //     this.barChartLabels[1]='Hangingwall'
            //   }
      
    }
    else if(this.rqd_hw<=100){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[3]   =SetColors[3]
      (<any>this.ChartColors[0].backgroundColor[1])=SetColors[3];
      // this.barChartLabels[1]='Hangingwall\nGood'
      // if(this.rqd_hw>0){

      //   this.barChartLabels[1]='Hangingwall\nGood'
      // }
      //   else 
      //   {
      //     this.barChartLabels[1]='Hangingwall'
      //   }



    }
    else{

    }
    console.log("rqd fW: ",this.rqd_fw)

    if(this.rqd_fw>0 && this.rqd_fw<=25){
      console.log("--0-before  poped00-0-> FWWWWWWW,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[0]   =SetColors[0]

      (<any>this.ChartColors[0].backgroundColor[2])=SetColors[0];
      // if(this.rqd_fw>0)
      // this.barChartLabels[2]='Footwall\nvery Poor'
      // else{
      //   this.barChartLabels[2]='Footwall'

      // }
    }
    else if(this.rqd_fw<=50){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[1]   =SetColors[1]
      (<any>this.ChartColors[0].backgroundColor[2])=SetColors[1];
      // if(this.rqd_fw>0)
      // this.barChartLabels[2]='Footwall\n Poor'
      // else{
      //   this.barChartLabels[2]='Footwall'

      // }
    }
    else if(this.rqd_fw<=75){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[2]   =SetColors[2]

            (<any>this.ChartColors[0].backgroundColor[2])=SetColors[2];
            // this.barChartLabels[2]='Footwall\nFair'
            // if(this.rqd_fw>0)
            // this.barChartLabels[2]='Footwall\nFair'
            // else{
            //   this.barChartLabels[2]='Footwall'
      
            // }

    }
    else if(this.rqd_fw<=100){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[3]   =SetColors[3]
      (<any>this.ChartColors[0].backgroundColor[2])=SetColors[3];
      // this.barChartLabels[2]='Footwall\nGood'
      // if(this.rqd_fw>0)
      // this.barChartLabels[2]='Footwall\nGood'
      // else{
      //   this.barChartLabels[2]='Footwall'

      // }



    }
    else{

    }

    if(this.rqd_sw1>0 && this.rqd_sw1<=25){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[0]   =SetColors[0]

      (<any>this.ChartColors[0].backgroundColor[3])=SetColors[0];
      // this.barChartLabels[3]='Sidewall 1\nvery Poor'
      // if(this.rqd_sw1>0)
      // this.barChartLabels[3]='Sidewall\nvery Poor'
      // else{
      //   this.barChartLabels[3]='Sidewall'

      // }

    }
    else if(this.rqd_sw1<=50){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[1]   =SetColors[1]
      (<any>this.ChartColors[0].backgroundColor[3])=SetColors[1];
      // this.barChartLabels[3]='Sidewall 1\n Poor'
      // if(this.rqd_sw1>0)
      // this.barChartLabels[3]='Sidewall\n Poor'
      // else{
      //   this.barChartLabels[3]='Sidewall'

      // }
    }
    else if(this.rqd_sw1<=75){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[2]   =SetColors[2]

            (<any>this.ChartColors[0].backgroundColor[3])=SetColors[2];
            // this.barChartLabels[3]='Sidewall 1\nFair'
            // if(this.rqd_sw1>0)
            // this.barChartLabels[3]='Sidewall\nFair'
            // else{
            //   this.barChartLabels[3]='Sidewall'
      
            // }

    }
    else if(this.rqd_sw1<=100){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[3]   =SetColors[3]
      (<any>this.ChartColors[0].backgroundColor[3])=SetColors[3];
      // this.barChartLabels[3]='Sidewall 1\nGood'
      // if(this.rqd_sw1>0)
      // this.barChartLabels[3]='Sidewall\nGood'
      // else{
      //   this.barChartLabels[3]='Sidewall'

      // }



    }
    else{

    }

    if(this.rqd_sw2>0 && this.rqd_sw2<=25){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[0]   =SetColors[0]

      (<any>this.ChartColors[0].backgroundColor[4])=SetColors[0];
      // this.barChartLabels[4]='Sidewall 2\nvery Poor'
      // if(this.rqd_sw2>0)
      // this.barChartLabels[4]='Sidewall\nvery Poor'
      // else{
      //   this.barChartLabels[4]='Sidewall'

      // }

    }
    else if(this.rqd_sw2<=50){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[1]   =SetColors[1]
      (<any>this.ChartColors[0].backgroundColor[4])=SetColors[1];
      // this.barChartLabels[4]='Sidewall 2\n Poor'
      // if(this.rqd_sw2>0)
      // this.barChartLabels[4]='Sidewall\nPoor'
      // else{
      //   this.barChartLabels[4]='Sidewall'

      // }
    }
    else if(this.rqd_sw2<=75){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[2]   =SetColors[2]

            (<any>this.ChartColors[0].backgroundColor[4])=SetColors[2];
      //       this.barChartLabels[4]='Sidewall 2\nFair'
      //       if(this.rqd_sw2>0)
      // this.barChartLabels[4]='Sidewall\nFair'
      // else{
      //   this.barChartLabels[4]='Sidewall'

      // }

    }
    else if(this.rqd_sw2<=100){
      console.log("--0-before  poped00-0->,",      this.ChartColors  );
      // this.ChartColors[0].backgroundColor=[...this.ChartColors[0].backgroundColor];
      // this.ChartColors[0].backgroundColor[3]   =SetColors[3]
      (<any>this.ChartColors[0].backgroundColor[4])=SetColors[3];
      // this.barChartLabels[4]='Sidewall 2\n Good'
      // if(this.rqd_sw2>0)
      // this.barChartLabels[4]='Sidewall\n Good'
      // else{
      //   this.barChartLabels[4]='Sidewall'

      // }



    }
    else{

    }
  }
}
