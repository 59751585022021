<mat-dialog-content>
    <p>Thank you for sharing case history data. 
    It will help to increase the reliability of the stability prediction of open stopes. 
    </p>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button mat-primary"
    id="close" [mat-dialog-close]="true">Close
    </button>
</mat-dialog-actions>