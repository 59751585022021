<!-- <button tabindex="-1" mat-button class="close" 
[mat-dialog-close]="true" style="font-size:1.5vw">x
</button> -->

<!-- <h1 mat-dialog-title>Select Jr that matches your condition</h1> -->

<div mat-dialog-content>

  <!-- <h6><i>1) Rock-wall contact, and rock-wall contact before 10 cm of shear</i></h6> -->

  <div mat-dialog-actions>
    
    <div class="jrh">
      <!-- <h1>Predication underway</h1> -->
      <p>The probabilistic prediction of stope surface stabilities is in progress.Please wait a few seconds</p>
    </div>
  </div>
</div>
