<div class="col-40">
  <div id="QModified">
    <h3 style="font-size:1.4vw; color:#474747; margin-bottom:0%;margin-left:15%; font-weight:500;">The Modified Q </h3>
    <canvas baseChart
      [datasets]="barChartDataQ"
      [labels]="barChartLabelsQModified"
      [options]="barChartOptionsQ"
      [plugins]="barChartPlugins"
      [legend]="barChartLegendQ"
      [chartType]="barChartType"
      [colors]="ChartColorsRQD">
    </canvas>
  </div>

  <div id="rqd">
    <h3 style="font-size:1.4vw; color:#474747; margin-bottom:0%;font-weight:500;">RQD - Rock Quality Designation </h3>
    <img src="assets/img/core.png" alt="Core sample" style="width:100%; margin: 1% 13% 2% 3%;">
    <canvas baseChart
      [datasets]="barChartDataRQD"
      [labels]="barChartLabels"
      [options]="barChartOptionsRQD"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartType"
      [colors]="ChartColors">
    </canvas>
  </div>
</div>

<div class="next">
  <button class="mat-raised-button mat-primary" routerLink="/stress">Next &raquo;</button>
</div>

